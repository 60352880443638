export const en_trans={
	"UG9zdGFsIENvZGU=":"Postal Code",
	"TG9naW4=":"Login",
	"ZmllbGQgaXMgcmVxdWlyZWQ=":"field is required",
	"ZmllbGQgbmVlZHMgdG8gYmUgYSBudW1iZXI=":"field needs to be a number",
	"ZmllbGQgbXVzdCBiZSBhIHZhbGlkIGVtYWlsIGFkZHJlc3M=":"field must be a valid email address",
	"ZmllbGQgbXVzdCBiZSBhdCBsZWFzdA==":"field must be at least",
	"Y2hhcmFjdGVycw==":"characters",
	"ZmllbGQgbWF5IG5vdCBiZSBncmVhdGVyIHRoYW4=":"field may not be greater than",
	"VGhpcw==":"This",
	"Q29ubmVjdA==":"Connect",
	"dG8gY29udGludWU=":"to continue",
	"T24gdGhpcyB3ZWJzaXRlIHlvdSBjYW4gY29uZmlybSBvciBjaGFuZ2UgeW91ciBhcHBvaW50bWVudCBwcm9wb3NhbA==":"Enter your postal code to confirm or modify the appointment proposal",
	"eW91ciBhcHBvaW50bWVudCBwcm9wb3NhbA==":"Your appointment proposal",
	"eW91IGhhdmUgYW4gdW5jb25maXJtZWQgYXBwb2ludG1lbnQgZm9y":"An appointment is awaiting confirmation for",
	"Q29uZmlybSB0aGlzIGFwcG9pbnRtZW50":"Confirm this appointment",
	"Q2hvb3NlIGFub3RoZXIgZGF0ZQ==":"Choose another date",
	"TmV4dCBwZXJpb2Q=":"Next period",
	"TmV4dCBtb250aA==":"Next month",
	"U2VsZWN0IGEgcGVyaW9kIHlvdXJzZWxm":"Select another period",
	"Tm9uZSBvZiB0aGVzZSBkYXRlcyBmaXQ=":"None of these dates suit you",
	"SnVzdCB0aGlz":"One more step",
	"QmVmb3JlIHlvdSBjYW4gY29uZmlybSB5b3VyIGFwcG9pbnRtZW50LCB3ZSBhc2sgeW91IHRvIGNoZWNrIHlvdXIgZGV0YWlscyBhbmQgY29ycmVjdCB0aGVtIHdoZXJlIHBvc3NpYmxlIGFuZCBuZWNlc3Nhcnk=":"Could you please check your details and correct them if necessary",
	"QWRkcmVzcyBvZiB0aGUgaW5zdGFsbGF0aW9u":"Address of the installation",
	"SW5zdGFsbGF0aW9uIGRhdGE=":"Type of heating installation",
	"WW91ciBkYXRh":"Your data",
	"TW9iaWxlIHBob25l":"Mobile phone",
	"RS1tYWlsIGFkZHJlc3M=":"E-mail address",
	"SXQgaXMgbm90IGEgZ2FzIGluc3RhbGxhdGlvbg==":"It is not a gas installation",
	"VGhlIGFkZHJlc3MgaXMgbm90IChjb21wbGV0ZWx5KSBjb3JyZWN0":"The address is not (completely) correct",
	"VGhlIGluZm9ybWF0aW9uIGFib3ZlIGlzIGNvcnJlY3Q=":"The information above is correct and I confirm the appointment",
	"WW91ciBhcHBvaW50bWVudCBoYXMgYmVlbiBjb25maXJtZWQgZm9y":"Your appointment has been confirmed for",
	"WW91ciBvcmRlciBmb3I=":"Your order for",
	"YmV0d2Vlbg==":"between",
	"d2FzIGNvbmZpcm1lZA==":"was confirmed",
	"SXQgYXBwZWFycyB0aGF0IHlvdSBoYXZlIGNoYW5nZWQgb25lIG9yIG1vcmUgb2YgeW91ciBjb250YWN0IGRldGFpbHM=":"You have adjusted one or more of your details",
	"VGhpcyBhZGp1c3RtZW50IG9ubHkgYXBwbGllcyB0byB0aGlzIG9uZSBpbnRlcnZlbnRpb24=":"This adjustment only applies to this intervention",
	"V2UgcmVjb21tZW5kIHRoYXQgeW91IGNvbnRhY3QgRW5naWUgb24gMDc4IDM1MyA3ODAgdG8gcGVybWFuZW50bHkgYWRqdXN0IHlvdXIgZGF0YQ==":"For these changes to be permanent, we recommend that you contact Engie on 078 35 33 34",
	"U2VsZWN0IGRhdGU=":"Select another date",
	"V2UnbGwgc2VlIHlvdQ==":"It’s in order! Your appointment is confirmed for",
	"WW91ciBhcHBvaW50bWVudCBwcm9wb3NhbCBmb3I=":"Your appointment proposal for",
	"Z290IGNhbmNlbGxlZA==":"has been cancelled",
	"RW5naWUgd2lsbCBjb250YWN0IHlvdSBzaG9ydGx5":"Engie will contact you shortly",
	"UXVlc3Rpb25z":"Any Questions",
	"Q2FsbA==":"Call",
	"SW4gd2hpY2ggcGVyaW9k":"In which period",
	"T24gd2hpY2ggZGF5cw==":"On which days",
	"QXQgd2hhdCB0aW1l":"At what time",
	"U2VhcmNoIHByb3Bvc2Fs":"Search",
	"TW9u":"Mon",
	"VHVl":"Tue",
	"V2Vk":"Wed",
	"VGh1":"Thu",
	"RnJp":"Fri",
	"TW9uZGF5":"Monday",
	"VHVlc2RheQ==":"Tuesday",
	"V2VkbmVzZGF5":"Wednesday",
	"VGh1cnNkYXk=":"Thursday",
	"RnJpZGF5":"Friday",
	"Q2hvb3NlIHRoaXMgZGF0ZQ==":"Choose this date",
	"Q29uZmlybSBhcHBvaW50bWVudA==":"Confirm appointment",
	"V3JvbmcgcG9zdGFsIGNvZGU=":"Wrong postal code",
	"dW50aWxs":"untill",
	"U29ycnksIHdlIGNvdWxkbid0IGZpbmQgYSB0aW1lIGZvciB5b3UuIEFkanVzdCB5b3VyIHNlYXJjaCBjcml0ZXJpYSBhbmQgdHJ5IGFnYWlu":"No available slots were found for your selection, please retry with a another or shorter period",
	"VGhlcmUgd2FzIGFuIGVycm9yIHdoaWxlIHJldHJpZXZpbmcgcHJvcG9zYWxzLiBUcnkgYWdhaW4=":"There was an error while retrieving proposals. Try again",
	"R2Fz":"Gas",
	"RnVlbA==":"Fuel",
	"WW91ciBjb25maXJtZWQgYXBwb2lubWVudA==":"Your confirmed appoinment",
	"VGhlIGVtYWlsIGZpZWxkIE9SIHBob25lIG51bWJlciBuZWVkcyB0byBiZSBmaWxsZWQ=":"The email field OR phone number needs to be filled",
	"ZmllbGQgbXVzdCBiZSBhIHZhbGlkIHBob25l":"field must be a valid phone",
	"ZnJvbQ==":"from",
	"b24=":"on",
	"SXQgaXMgbm90IGEgZnVlbCBpbnN0YWxsYXRpb24=":"It is not a fuel installation",
	"UG9zdHBvbmUgbXkgbWFpbnRlbmFuY2UgdG8gYSBsYXRlciB0aW1l":"Postpone my maintenance to a later time",
	"WW91ciBtYWludGVuYW5jZSBoYXMgYmVlbiBwb3N0cG9uZWQ=":"Your maintenance has been postponed",
	"Q29uZmlybQ==":"Confirm",
	"Q2FuY2Vs":"Cancel",
	"U2VsZWN0":"Select",
	"SmFudWFyeQ==":"January",
	"RmVicnVhcnk=":"February",
	"TWFyY2g=":"March",
	"QXByaWw=":"April",
	"TWF5":"May",
	"SnVuZQ==":"June",
	"SnVseQ==":"July",
	"QXVndXN0":"August",
	"U2VwdGVtYmVy":"September",
	"T2N0b2Jlcg==":"October",
	"Tm92ZW1iZXI=":"November",
	"RGVjZW1iZXI=":"December",
	"YW5k":"and",
	"Tm8gYXZhaWxhYmxlIHNsb3RzIGZvdW5kIGJldHdlZW4=":"No available slots found between",
	"VW5mb3J0dW5hdGVseSB5b3VyIHByZXZpb3VzbHkgcHJvcG9zZWQgZGF0ZSBpcyBubyBsb25nZXIgYXZhaWxhYmxlLiBQbGVhc2UgY2hvb3NlIGEgZGlmZmVyZW50IHRpbWUgZm9yIHlvdXIgYm9pbGVyIG1haW50ZW5hbmNlLg==":"Unfortunately your previously proposed date is no longer available. Please choose a different time for your boiler maintenance.",
	"UG9zdHBvbmUgbXkgbWFpbnRlbmFuY2UgdG8=":"Postpone my maintenance to",
	"VGhlcmUgYXJlIG5vIGF2YWlsYWJsZSBzbG90cyBpbiB0aGUgbmV4dCBmZXcgZGF5cy4gUGxlYXNlIHNlYXJjaCBpbiB0aGUgZm9sbG93aW5nIHBlcmlvZA==":"There are no available slots found in the next few days. Please search in the following period",
	"VW5mb3J0dW5hdGVseSBpdCdzIG5vIGxvbmdlciBwb3NzaWJsZSB0byBhZGFwdCB0aGlzIGludGVydmVudGlvbiBvbi1saW5lIGFzIGl0J3MgdG9vIGNsb3NlIHRvIHRoZSBleGVjdXRpb24gdGltZS4gUGxlYXNlIGNvbnRhY3QgdXMgZm9yIG90aGVyIGlucXVpcmllcw==":"Unfortunately it's no longer possible to adapt this intervention on-line as it's too close to the execution time. Please contact us for other inquiries",
	"VW5mb3J0dW5hdGVseSB0aGUgY3VycmVudCBzdGF0dXMgb2YgdGhpcyBpbnRlcnZlbnRpb24gZG9lcyBub3QgYWxsb3cgb24tbGluZSByZS1wbGFubmluZy4gUGxlYXNlIGNvbnRhY3QgdXMgZm9yIG90aGVyIGlucXVpcmllcw==":"Unfortunately the current status of this intervention does not allow on-line (re)planning. Please contact us for other inquiries",
	"UGxhbiB5b3VyIGFwcG9pbnRtZW50":"Plan your appointment",
	"WW91IGNhbiBzZWxmLXBsYW4geW91ciAiTWFpbnRlbmFuY2UiIGludGVydmVudGlvbiBieSBzZWxlY3Rpbmcgb25lIG9mIHRoZSBwcm9wb3NlZCBkYXRlcyBpbiB0aGUgc2NyZWVuIG9uIHRoZSByaWdodC4gV2Ugd2lsbCBhc2sgeW91IHRvIGNvbmZpcm0geW91ciBwZXJzb25hbCBpbmZvcm1hdGlvbiBpbiB0aGUgbmV4dCBzdGVwLg==":"You can self-plan your \"Maintenance\" intervention by selecting one of the proposed dates in the screen on the right. We will ask you to confirm your personal information in the next step.",
	"Q2hvb3NlIGEgZGF0ZQ==":"Choose a date",
	"Rm9yIGFuIG9wdGltYWwgcmVzdWx0LCBzZWFyY2ggdGhlIGNhbGVuZGFyIHBlciBwZXJpb2Qgb2YgMTQgZGF5cw==":"For an optimal result, search the calendar per period of 14 days",
	"QXZvaWQgdXNlbGVzcyBjb3N0cw==":"Avoid useless costs!",
	"aWYgeW91ciBib2lsZXIgZG9lcyBub3Qgd29yayBvciBkaXNwbGF5cyBlcnJvciBtZXNzYWdlcywgaXQgaGFzIHRvIGJlIHJlcGFpcmVkIGZpcnN0LiBZb3UgY2FuIGNvbnRhY3QgdXMgZm9yIHRoaXM=":"if your boiler does not work or displays error messages, it has to be repaired first. You can contact us for this",
	"dGhlIHRlY2huaWNpYW4gaGFzIGVhc3kgYWNjZXNzIHRvIHlvdXIgYm9pbGVyIChyZW1vdmUgYW55IG9ic3RhY2xlcyBpZiBuZWVkZWQpIGFuZCB0aGUgaW50ZXJ2ZW50aW9uIGNhbiBiZSBleGVjdXRlZCBzYWZlbHk=":"the technician has easy access to your boiler (remove any obstacles if needed) and the intervention can be executed safely",
	"b3VyIHRlY2huaWNpYW4gaGFzIHRoZSBvcHRpb24gdG8gcGFyayBuZWFyYnkuIEl0IGlzIGJlc3QgdG8gdGFrZSBpbnRvIGFjY291bnQgbWFya2V0IGRheXMsIGZhaXJzLCByb2FkIHdvcmtzLC4uLg==":"our technician has the option to park nearby. It is best to take into account market days, fairs, road works,...",
	"T0s=":"OK",
	"SSBnZXQgaXQ=":"I get it",
	"UGxlYXNlIG1ha2Ugc3VyZSB0aGF0":"Please make sure that:",
	"QmFjaw==":"Back",
	"QXR0ZW50aW9uIQ==":"Attention!",
	"WW91IGFyZSBhYm91dCB0byBpbmZvcm0gdXMgdGhhdCBpdCBpcyBub3QgYSBnYXMgaW5zdGFsbGF0aW9u":"You are about to inform us that it is not a gas installation",
	"WW91IGFyZSBhYm91dCB0byBpbmZvcm0gdXMgdGhhdCBpdCBpcyBub3QgYSBmdWVsIGluc3RhbGxhdGlvbg==":"You are about to inform us that it is not a fuel installation",
	"WW91ciBhcHBvaW50bWVudCB3aWxsIGJlIGNhbmNlbGxlZCBpZiB5b3UgY29uZmlybQ==":"Your appointment will be cancelled if you confirm"
};