import React from 'react';

export default class DatePickerInput extends React.Component{

	render(){
		return (
			<input type="text" className="form-control date-input bg-transparent" 
			value={this.props.value} 
			onClick={this.props.onClick} 
			readOnly
			placeholder="DD/MM/YYYY"/>
		);
	}

}