export const nl_trans={
	"UG9zdGFsIENvZGU=":"Postcode",
	"TG9naW4=":"Inloggen",
	"ZmllbGQgaXMgcmVxdWlyZWQ=":"veld is verplicht",
	"ZmllbGQgbmVlZHMgdG8gYmUgYSBudW1iZXI=":"veld moet een getal zijn",
	"ZmllbGQgbXVzdCBiZSBhIHZhbGlkIGVtYWlsIGFkZHJlc3M=":"veld moet een geldig e-mailadres zijn",
	"ZmllbGQgbXVzdCBiZSBhdCBsZWFzdA==":"veld moet minimaal zijn",
	"Y2hhcmFjdGVycw==":"karakters",
	"ZmllbGQgbWF5IG5vdCBiZSBncmVhdGVyIHRoYW4=":"veld mag niet groter zijn dan",
	"VGhpcw==":"Dit",
	"Q29ubmVjdA==":"Meld je aan",
	"dG8gY29udGludWU=":"om verder te gaan",
	"T24gdGhpcyB3ZWJzaXRlIHlvdSBjYW4gY29uZmlybSBvciBjaGFuZ2UgeW91ciBhcHBvaW50bWVudCBwcm9wb3NhbA==":"Geef je postcode in om je afspraak te bevestigen of te wijzigen",
	"eW91ciBhcHBvaW50bWVudCBwcm9wb3NhbA==":"Uw afspraakvoorstel",
	"eW91IGhhdmUgYW4gdW5jb25maXJtZWQgYXBwb2ludG1lbnQgZm9y":"Bevestig je afspraak voor",
	"Q29uZmlybSB0aGlzIGFwcG9pbnRtZW50":"Bevestig deze afspraak",
	"Q2hvb3NlIGFub3RoZXIgZGF0ZQ==":"Een andere datum kiezen",
	"TmV4dCBwZXJpb2Q=":"Volgende periode",
	"TmV4dCBtb250aA==":"Volgende maand",
	"U2VsZWN0IGEgcGVyaW9kIHlvdXJzZWxm":"Zelf een periode selecteren",
	"Tm9uZSBvZiB0aGVzZSBkYXRlcyBmaXQ=":"Past geen van deze data",
	"SnVzdCB0aGlz":"Nog even dit",
	"QmVmb3JlIHlvdSBjYW4gY29uZmlybSB5b3VyIGFwcG9pbnRtZW50LCB3ZSBhc2sgeW91IHRvIGNoZWNrIHlvdXIgZGV0YWlscyBhbmQgY29ycmVjdCB0aGVtIHdoZXJlIHBvc3NpYmxlIGFuZCBuZWNlc3Nhcnk=":"Controleer je gegevens en wijzig waar nodig",
	"QWRkcmVzcyBvZiB0aGUgaW5zdGFsbGF0aW9u":"Adres van de installatie",
	"SW5zdGFsbGF0aW9uIGRhdGE=":"Installatiegegevens",
	"WW91ciBkYXRh":"Uw gegevens",
	"TW9iaWxlIHBob25l":"Mobiele telefoon",
	"RS1tYWlsIGFkZHJlc3M=":"E-mail adres",
	"SXQgaXMgbm90IGEgZ2FzIGluc3RhbGxhdGlvbg==":"Het gaat niet om een installatie op gas",
	"VGhlIGFkZHJlc3MgaXMgbm90IChjb21wbGV0ZWx5KSBjb3JyZWN0":"Het adres is niet (volledig) juist",
	"VGhlIGluZm9ybWF0aW9uIGFib3ZlIGlzIGNvcnJlY3Q=":"De gegevens hierboven zijn correct en ik bevestig de afspraak",
	"WW91ciBhcHBvaW50bWVudCBoYXMgYmVlbiBjb25maXJtZWQgZm9y":"Uw afspraak werd bevestigd voor",
	"WW91ciBvcmRlciBmb3I=":"Uw opdracht voor",
	"YmV0d2Vlbg==":"tussen",
	"d2FzIGNvbmZpcm1lZA==":"werd bevestigt",
	"SXQgYXBwZWFycyB0aGF0IHlvdSBoYXZlIGNoYW5nZWQgb25lIG9yIG1vcmUgb2YgeW91ciBjb250YWN0IGRldGFpbHM=":"Het lijkt erop dat u een of meerdere van uw contactgegevens hebt gewijzigd",
	"VGhpcyBhZGp1c3RtZW50IG9ubHkgYXBwbGllcyB0byB0aGlzIG9uZSBpbnRlcnZlbnRpb24=":"Deze aanpassing is enkel van toespassing op deze ene interventie",
	"V2UgcmVjb21tZW5kIHRoYXQgeW91IGNvbnRhY3QgRW5naWUgb24gMDc4IDM1MyA3ODAgdG8gcGVybWFuZW50bHkgYWRqdXN0IHlvdXIgZGF0YQ==":"We raden u aan contact op te nemen met Engie via 078 35 33 34 om uw gegevens duurzaam aan te passen",
	"U2VsZWN0IGRhdGU=":"Selecteer een andere datum",
	"V2UnbGwgc2VlIHlvdQ==":"Wij zien u op",
	"WW91ciBhcHBvaW50bWVudCBwcm9wb3NhbCBmb3I=":"Uw afspraakvoostel voor",
	"Z290IGNhbmNlbGxlZA==":"werd geannuleerd",
	"RW5naWUgd2lsbCBjb250YWN0IHlvdSBzaG9ydGx5":"Engie zal binnenkort contact met u opnemen",
	"UXVlc3Rpb25z":"Vragen",
	"Q2FsbA==":"Bel",
	"SW4gd2hpY2ggcGVyaW9k":"In welke periode",
	"T24gd2hpY2ggZGF5cw==":"Op welke dagen",
	"QXQgd2hhdCB0aW1l":"Op welk tijdstip",
	"U2VhcmNoIHByb3Bvc2Fs":"Zoeken",
	"TW9u":"Ma",
	"VHVl":"Di",
	"V2Vk":"Wo",
	"VGh1":"Do",
	"RnJp":"Vr",
	"TW9uZGF5":"Maandag",
	"VHVlc2RheQ==":"Dinsdag",
	"V2VkbmVzZGF5":"Woensdag",
	"VGh1cnNkYXk=":"Donderdag",
	"RnJpZGF5":"Vrijdag",
	"Q2hvb3NlIHRoaXMgZGF0ZQ==":"Kies deze datum",
	"Q29uZmlybSBhcHBvaW50bWVudA==":"Bevestig afspraak",
	"V3JvbmcgcG9zdGFsIGNvZGU=":"Verkeerde postcode",
	"dW50aWxs":"tot",
	"U29ycnksIHdlIGNvdWxkbid0IGZpbmQgYSB0aW1lIGZvciB5b3UuIEFkanVzdCB5b3VyIHNlYXJjaCBjcml0ZXJpYSBhbmQgdHJ5IGFnYWlu":"Er zijn geen beschikbare slots gevonden voor uw selectie, probeer het opnieuw met een andere of kortere periode",
	"VGhlcmUgd2FzIGFuIGVycm9yIHdoaWxlIHJldHJpZXZpbmcgcHJvcG9zYWxzLiBUcnkgYWdhaW4=":"Er is een fout opgetreden bij het ophalen van voorstellen. Probeer het nog eens",
	"R2Fz":"Gas",
	"RnVlbA==":"Stookolie",
	"WW91ciBjb25maXJtZWQgYXBwb2lubWVudA==":"Uw bevestigde afspraak",
	"VGhlIGVtYWlsIGZpZWxkIE9SIHBob25lIG51bWJlciBuZWVkcyB0byBiZSBmaWxsZWQ=":"Het e-mailveld OF telefoonnummer moet worden ingevuld",
	"ZmllbGQgbXVzdCBiZSBhIHZhbGlkIHBob25l":"veld moet een geldige telefoon zijn",
	"ZnJvbQ==":"van",
	"b24=":"op",
	"SXQgaXMgbm90IGEgZnVlbCBpbnN0YWxsYXRpb24=":"Het gaat niet om een installatie op stookolie",
	"UG9zdHBvbmUgbXkgbWFpbnRlbmFuY2UgdG8gYSBsYXRlciB0aW1l":"Mijn ketelonderhoud uitstellen naar een later moment",
	"WW91ciBtYWludGVuYW5jZSBoYXMgYmVlbiBwb3N0cG9uZWQ=":"Uw ketelonderhoud is uitgesteld",
	"Q29uZmlybQ==":"Bevestig",
	"Q2FuY2Vs":"Annuleren",
	"U2VsZWN0":"Selecteer",
	"SmFudWFyeQ==":"Januari",
	"RmVicnVhcnk=":"Februari",
	"TWFyY2g=":"Maart",
	"QXByaWw=":"April",
	"TWF5":"Mei",
	"SnVuZQ==":"Juni",
	"SnVseQ==":"Juli",
	"QXVndXN0":"Augustus",
	"U2VwdGVtYmVy":"September",
	"T2N0b2Jlcg==":"Oktober",
	"Tm92ZW1iZXI=":"November",
	"RGVjZW1iZXI=":"December",
	"YW5k":"en",
	"Tm8gYXZhaWxhYmxlIHNsb3RzIGZvdW5kIGJldHdlZW4=":"Geen beschikbare slots gevonden tussen",
	"VW5mb3J0dW5hdGVseSB5b3VyIHByZXZpb3VzbHkgcHJvcG9zZWQgZGF0ZSBpcyBubyBsb25nZXIgYXZhaWxhYmxlLiBQbGVhc2UgY2hvb3NlIGEgZGlmZmVyZW50IHRpbWUgZm9yIHlvdXIgYm9pbGVyIG1haW50ZW5hbmNlLg==":"Je eerder voorgestelde datum is jammer genoeg niet meer beschikbaar. Gelieve een ander moment te kiezen voor uw ketelonderhoud.",
	"UG9zdHBvbmUgbXkgbWFpbnRlbmFuY2UgdG8=":"Mijn ketelonderhoud uitstellen naar",
	"VGhlcmUgYXJlIG5vIGF2YWlsYWJsZSBzbG90cyBpbiB0aGUgbmV4dCBmZXcgZGF5cy4gUGxlYXNlIHNlYXJjaCBpbiB0aGUgZm9sbG93aW5nIHBlcmlvZA==":"Er zijn geen beschikbare sloten gevonden in de eerstkomende dagen. Gelieve te zoeken in de volgende periode",
	"VW5mb3J0dW5hdGVseSBpdCdzIG5vIGxvbmdlciBwb3NzaWJsZSB0byBhZGFwdCB0aGlzIGludGVydmVudGlvbiBvbi1saW5lIGFzIGl0J3MgdG9vIGNsb3NlIHRvIHRoZSBleGVjdXRpb24gdGltZS4gUGxlYXNlIGNvbnRhY3QgdXMgZm9yIG90aGVyIGlucXVpcmllcw==":"Het is niet meer mogelijk om deze interventie online aan te passen aangezien de uitvoeringsdatum te kortbij is. Gelieve ons te contacteren voor dringende verzoeken",
	"VW5mb3J0dW5hdGVseSB0aGUgY3VycmVudCBzdGF0dXMgb2YgdGhpcyBpbnRlcnZlbnRpb24gZG9lcyBub3QgYWxsb3cgb24tbGluZSByZS1wbGFubmluZy4gUGxlYXNlIGNvbnRhY3QgdXMgZm9yIG90aGVyIGlucXVpcmllcw==":"De huidige status van deze interventie laat helaas geen online (her)planning toe. Neem contact met ons op voor andere vragen",
	"UGxhbiB5b3VyIGFwcG9pbnRtZW50":"Plan je afspraak",
	"WW91IGNhbiBzZWxmLXBsYW4geW91ciAiTWFpbnRlbmFuY2UiIGludGVydmVudGlvbiBieSBzZWxlY3Rpbmcgb25lIG9mIHRoZSBwcm9wb3NlZCBkYXRlcyBpbiB0aGUgc2NyZWVuIG9uIHRoZSByaWdodC4gV2Ugd2lsbCBhc2sgeW91IHRvIGNvbmZpcm0geW91ciBwZXJzb25hbCBpbmZvcm1hdGlvbiBpbiB0aGUgbmV4dCBzdGVwLg==":"U kunt zelf uw \"Onderhoud\" afspraak plannen door in het rechter scherm één van de voorgestelde data te selecteren. In de volgende stap vragen we je om je persoonlijke gegevens en de gekozen datum te bevestigen.",
	"Q2hvb3NlIGEgZGF0ZQ==":"Kies een datum",
	"Rm9yIGFuIG9wdGltYWwgcmVzdWx0LCBzZWFyY2ggdGhlIGNhbGVuZGFyIHBlciBwZXJpb2Qgb2YgMTQgZGF5cw==":"Voor een optimaal resultaat, zoek in de agenda telkens per periode van 14 dagen",
	"QXZvaWQgdXNlbGVzcyBjb3N0cw==":"Vermijd nutteloze kosten!",
	"aWYgeW91ciBib2lsZXIgZG9lcyBub3Qgd29yayBvciBkaXNwbGF5cyBlcnJvciBtZXNzYWdlcywgaXQgaGFzIHRvIGJlIHJlcGFpcmVkIGZpcnN0LiBZb3UgY2FuIGNvbnRhY3QgdXMgZm9yIHRoaXM=":"indien je ketel niet werkt of foutboodschappen weergeeft, je eerst je ketel laat herstellen.  Je kan hiervoor contact opnemen met ons",
	"dGhlIHRlY2huaWNpYW4gaGFzIGVhc3kgYWNjZXNzIHRvIHlvdXIgYm9pbGVyIChyZW1vdmUgYW55IG9ic3RhY2xlcyBpZiBuZWVkZWQpIGFuZCB0aGUgaW50ZXJ2ZW50aW9uIGNhbiBiZSBleGVjdXRlZCBzYWZlbHk=":"de technieker makkelijk toegang heeft tot je ketel (verwijder eventuele hindernissen)  en de interventie in alle veiligheid kan doorgaan",
	"b3VyIHRlY2huaWNpYW4gaGFzIHRoZSBvcHRpb24gdG8gcGFyayBuZWFyYnkuIEl0IGlzIGJlc3QgdG8gdGFrZSBpbnRvIGFjY291bnQgbWFya2V0IGRheXMsIGZhaXJzLCByb2FkIHdvcmtzLC4uLg==":"onze technieker de mogelijkheid heeft tot parkeren in de buurt. Hou best rekening met marktdagen, kermis, wegenwerken,...",
	"T0s=":"OK",
	"SSBnZXQgaXQ=":"Ik snap het",
	"UGxlYXNlIG1ha2Ugc3VyZSB0aGF0":"Gelieve ervoor te zorgen dat:",
	"QmFjaw==":"Terug",
	"QXR0ZW50aW9uIQ==":"Opgelet!",
	"WW91IGFyZSBhYm91dCB0byBpbmZvcm0gdXMgdGhhdCBpdCBpcyBub3QgYSBnYXMgaW5zdGFsbGF0aW9u":"Je staat op het punt ons door te geven dat het niet om een installatie op gas gaat",
	"WW91IGFyZSBhYm91dCB0byBpbmZvcm0gdXMgdGhhdCBpdCBpcyBub3QgYSBmdWVsIGluc3RhbGxhdGlvbg==":"Je staat op het punt ons door te geven dat het niet om een installatie op stookolie gaat",
	"WW91ciBhcHBvaW50bWVudCB3aWxsIGJlIGNhbmNlbGxlZCBpZiB5b3UgY29uZmlybQ==":"Als je dit bevestigt wordt je afspraak geannuleerd"
};