export const fr_trans={
	"UG9zdGFsIENvZGU=":"Code Postal",
	"TG9naW4=":"Se connecter",
	"ZmllbGQgaXMgcmVxdWlyZWQ=":"champ est obligatoire",
	"ZmllbGQgbmVlZHMgdG8gYmUgYSBudW1iZXI=":"champ doit être un nombre",
	"ZmllbGQgbXVzdCBiZSBhIHZhbGlkIGVtYWlsIGFkZHJlc3M=":"champ doit être une adresse e-mail valide",
	"ZmllbGQgbXVzdCBiZSBhdCBsZWFzdA==":"champ doit être au moins",
	"Y2hhcmFjdGVycw==":"caractères",
	"ZmllbGQgbWF5IG5vdCBiZSBncmVhdGVyIHRoYW4=":"champ ne peut pas être supérieur à",
	"VGhpcw==":"Ce",
	"Q29ubmVjdA==":"Connectez-vous",
	"dG8gY29udGludWU=":"pour continuer",
	"T24gdGhpcyB3ZWJzaXRlIHlvdSBjYW4gY29uZmlybSBvciBjaGFuZ2UgeW91ciBhcHBvaW50bWVudCBwcm9wb3NhbA==":"Introduisez votre code postal afin de pouvoir confirmer ou modifier votre proposition de rendez-vous",
	"eW91ciBhcHBvaW50bWVudCBwcm9wb3NhbA==":"Votre proposition de rendez-vous",
	"eW91IGhhdmUgYW4gdW5jb25maXJtZWQgYXBwb2ludG1lbnQgZm9y":"Un rendez-vous est en attente de confirmation pour le",
	"Q29uZmlybSB0aGlzIGFwcG9pbnRtZW50":"Confirmer ce rendez-vous",
	"Q2hvb3NlIGFub3RoZXIgZGF0ZQ==":"Choisir une autre date",
	"TmV4dCBwZXJpb2Q=":"Prochaine période",
	"TmV4dCBtb250aA==":"Prochain mois",
	"U2VsZWN0IGEgcGVyaW9kIHlvdXJzZWxm":"Choisir une autre période",
	"Tm9uZSBvZiB0aGVzZSBkYXRlcyBmaXQ=":"Aucune de ces dates ne vous convient",
	"SnVzdCB0aGlz":"Encore une étape",
	"QmVmb3JlIHlvdSBjYW4gY29uZmlybSB5b3VyIGFwcG9pbnRtZW50LCB3ZSBhc2sgeW91IHRvIGNoZWNrIHlvdXIgZGV0YWlscyBhbmQgY29ycmVjdCB0aGVtIHdoZXJlIHBvc3NpYmxlIGFuZCBuZWNlc3Nhcnk=":"Pourriez-vous vérifier vos données et les corriger si nécessaire",
	"QWRkcmVzcyBvZiB0aGUgaW5zdGFsbGF0aW9u":"Adresse de l'installation",
	"SW5zdGFsbGF0aW9uIGRhdGE=":"Type d'installation de chauffage",
	"WW91ciBkYXRh":"Vos données",
	"TW9iaWxlIHBob25l":"Téléphone",
	"RS1tYWlsIGFkZHJlc3M=":"Adresse e-mail",
	"SXQgaXMgbm90IGEgZ2FzIGluc3RhbGxhdGlvbg==":"Ce n'est pas une installation au gaz",
	"VGhlIGFkZHJlc3MgaXMgbm90IChjb21wbGV0ZWx5KSBjb3JyZWN0":"L'adresse n'est pas (complètement) correcte",
	"VGhlIGluZm9ybWF0aW9uIGFib3ZlIGlzIGNvcnJlY3Q=":"Les informations ci-dessus sont correctes et je confirme le rendez-vous",
	"WW91ciBhcHBvaW50bWVudCBoYXMgYmVlbiBjb25maXJtZWQgZm9y":"Votre rendez-vous a été confirmé pour",
	"WW91ciBvcmRlciBmb3I=":"Votre commande pour",
	"YmV0d2Vlbg==":"entre",
	"d2FzIGNvbmZpcm1lZA==":"a été confirmé",
	"SXQgYXBwZWFycyB0aGF0IHlvdSBoYXZlIGNoYW5nZWQgb25lIG9yIG1vcmUgb2YgeW91ciBjb250YWN0IGRldGFpbHM=":"Vous avez modifié une ou plusieurs de vos coordonnées",
	"VGhpcyBhZGp1c3RtZW50IG9ubHkgYXBwbGllcyB0byB0aGlzIG9uZSBpbnRlcnZlbnRpb24=":"Cette adaptation ne s'applique qu'à cette intervention",
	"V2UgcmVjb21tZW5kIHRoYXQgeW91IGNvbnRhY3QgRW5naWUgb24gMDc4IDM1MyA3ODAgdG8gcGVybWFuZW50bHkgYWRqdXN0IHlvdXIgZGF0YQ==":"Pour que ces modifications soient permanentes, nous vous recommandons de contacter Engie au 078 35 33 34",
	"U2VsZWN0IGRhdGU=":"Sélectionner une autre date",
	"V2UnbGwgc2VlIHlvdQ==":"C'est en ordre ! Votre rendez-vous est confirmé pour le",
	"WW91ciBhcHBvaW50bWVudCBwcm9wb3NhbCBmb3I=":"Votre proposition de rendez-vous pour le",
	"Z290IGNhbmNlbGxlZA==":"a été annulé",
	"RW5naWUgd2lsbCBjb250YWN0IHlvdSBzaG9ydGx5":"Engie vous contactera prochainement",
	"UXVlc3Rpb25z":"Des questions",
	"Q2FsbA==":"Appeler",
	"SW4gd2hpY2ggcGVyaW9k":"À quelle période",
	"T24gd2hpY2ggZGF5cw==":"Quels jours",
	"QXQgd2hhdCB0aW1l":"A quel moment",
	"U2VhcmNoIHByb3Bvc2Fs":"Rechercher",
	"TW9u":"Lun",
	"VHVl":"Mar",
	"V2Vk":"Mer",
	"VGh1":"Jeu",
	"RnJp":"Ven",
	"TW9uZGF5":"Lundi",
	"VHVlc2RheQ==":"Mardi",
	"V2VkbmVzZGF5":"Mercredi",
	"VGh1cnNkYXk=":"Jeudi",
	"RnJpZGF5":"Vendredi",
	"Q2hvb3NlIHRoaXMgZGF0ZQ==":"Choisissez cette date",
	"Q29uZmlybSBhcHBvaW50bWVudA==":"Confirmer le rendez-vous",
	"V3JvbmcgcG9zdGFsIGNvZGU=":"Code postal erroné",
	"dW50aWxs":"jusqu'à",
	"U29ycnksIHdlIGNvdWxkbid0IGZpbmQgYSB0aW1lIGZvciB5b3UuIEFkanVzdCB5b3VyIHNlYXJjaCBjcml0ZXJpYSBhbmQgdHJ5IGFnYWlu":"Aucun créneau disponible n'a été trouvé pour votre sélection, veuillez réessayer avec une autre période ou une période plus courte",
	"VGhlcmUgd2FzIGFuIGVycm9yIHdoaWxlIHJldHJpZXZpbmcgcHJvcG9zYWxzLiBUcnkgYWdhaW4=":"Une erreur s'est produite lors de la récupération des propositions. Réessayer",
	"R2Fz":"Gaz",
	"RnVlbA==":"Mazout",
	"WW91ciBjb25maXJtZWQgYXBwb2lubWVudA==":"Votre rendez-vous confirmé",
	"VGhlIGVtYWlsIGZpZWxkIE9SIHBob25lIG51bWJlciBuZWVkcyB0byBiZSBmaWxsZWQ=":"Le champ e-mail OU le numéro de téléphone doit être rempli",
	"ZmllbGQgbXVzdCBiZSBhIHZhbGlkIHBob25l":"champ doit être un téléphone valide",
	"ZnJvbQ==":"de",
	"b24=":"au",
	"SXQgaXMgbm90IGEgZnVlbCBpbnN0YWxsYXRpb24=":"Ce n'est pas une installation de mazout",
	"UG9zdHBvbmUgbXkgbWFpbnRlbmFuY2UgdG8gYSBsYXRlciB0aW1l":"Reporter mon entretien à une date ultérieure",
	"WW91ciBtYWludGVuYW5jZSBoYXMgYmVlbiBwb3N0cG9uZWQ=":"L'entretien de votre chaudière a été reporté",
	"Q29uZmlybQ==":"Confirmer",
	"Q2FuY2Vs":"Annuler",
	"U2VsZWN0":"Sélectionner",
	"SmFudWFyeQ==":"Janvier",
	"RmVicnVhcnk=":"Février",
	"TWFyY2g=":"Mars",
	"QXByaWw=":"Avril",
	"TWF5":"Mai",
	"SnVuZQ==":"Juin",
	"SnVseQ==":"Juillet",
	"QXVndXN0":"Aout",
	"U2VwdGVtYmVy":"Septembre",
	"T2N0b2Jlcg==":"Octobre",
	"Tm92ZW1iZXI=":"Novembre",
	"RGVjZW1iZXI=":"Décembre",
	"YW5k":"et",
	"Tm8gYXZhaWxhYmxlIHNsb3RzIGZvdW5kIGJldHdlZW4=":"Aucun créneau disponible trouvé entre",
	"VW5mb3J0dW5hdGVseSB5b3VyIHByZXZpb3VzbHkgcHJvcG9zZWQgZGF0ZSBpcyBubyBsb25nZXIgYXZhaWxhYmxlLiBQbGVhc2UgY2hvb3NlIGEgZGlmZmVyZW50IHRpbWUgZm9yIHlvdXIgYm9pbGVyIG1haW50ZW5hbmNlLg==":"Malheureusement, votre date précédemment proposée n'est plus disponible. Veuillez choisir une autre date pour l'entretien de votre chaudière.",
	"UG9zdHBvbmUgbXkgbWFpbnRlbmFuY2UgdG8=":"Reporter mon entretien à",
	"VGhlcmUgYXJlIG5vIGF2YWlsYWJsZSBzbG90cyBpbiB0aGUgbmV4dCBmZXcgZGF5cy4gUGxlYXNlIHNlYXJjaCBpbiB0aGUgZm9sbG93aW5nIHBlcmlvZA==":"Aucune disponibilité n'a été trouvée dans les prochains jours. Veuillez en chercher une dans la prochaine période",
	"VW5mb3J0dW5hdGVseSBpdCdzIG5vIGxvbmdlciBwb3NzaWJsZSB0byBhZGFwdCB0aGlzIGludGVydmVudGlvbiBvbi1saW5lIGFzIGl0J3MgdG9vIGNsb3NlIHRvIHRoZSBleGVjdXRpb24gdGltZS4gUGxlYXNlIGNvbnRhY3QgdXMgZm9yIG90aGVyIGlucXVpcmllcw==":"Il n'est plus possible de modifier cette intervention en-ligne car la date de mise en œuvre est trop proche. Veuillez nous contacter pour les demandes urgentes",
	"VW5mb3J0dW5hdGVseSB0aGUgY3VycmVudCBzdGF0dXMgb2YgdGhpcyBpbnRlcnZlbnRpb24gZG9lcyBub3QgYWxsb3cgb24tbGluZSByZS1wbGFubmluZy4gUGxlYXNlIGNvbnRhY3QgdXMgZm9yIG90aGVyIGlucXVpcmllcw==":"Malheureusement, l'état actuel de cette intervention ne permet pas une (re)planification en ligne. Veuillez nous contacter pour d'autres demandes",
	"UGxhbiB5b3VyIGFwcG9pbnRtZW50":"Planifiez votre rendez-vous",
	"WW91IGNhbiBzZWxmLXBsYW4geW91ciAiTWFpbnRlbmFuY2UiIGludGVydmVudGlvbiBieSBzZWxlY3Rpbmcgb25lIG9mIHRoZSBwcm9wb3NlZCBkYXRlcyBpbiB0aGUgc2NyZWVuIG9uIHRoZSByaWdodC4gV2Ugd2lsbCBhc2sgeW91IHRvIGNvbmZpcm0geW91ciBwZXJzb25hbCBpbmZvcm1hdGlvbiBpbiB0aGUgbmV4dCBzdGVwLg==":"Vous pouvez planifier vous-même votre rendez-vous \"Maintenance\" en sélectionnant l'une des dates proposées dans l'écran de droite. Nous vous demanderons de confirmer vos informations personnelles à l'étape suivante.",
	"Q2hvb3NlIGEgZGF0ZQ==":"Choisissez une date",
	"Rm9yIGFuIG9wdGltYWwgcmVzdWx0LCBzZWFyY2ggdGhlIGNhbGVuZGFyIHBlciBwZXJpb2Qgb2YgMTQgZGF5cw==":"Pour un résultat optimal, recherchez dans le calendrier par période de 14 jours",
	"QXZvaWQgdXNlbGVzcyBjb3N0cw==":"Evitez les frais inutiles !",
	"aWYgeW91ciBib2lsZXIgZG9lcyBub3Qgd29yayBvciBkaXNwbGF5cyBlcnJvciBtZXNzYWdlcywgaXQgaGFzIHRvIGJlIHJlcGFpcmVkIGZpcnN0LiBZb3UgY2FuIGNvbnRhY3QgdXMgZm9yIHRoaXM=":"si votre chaudière ne fonctionne pas ou affiche des messages d'erreur, veillez d'abord à la faire réparer. Vous pouvez nous contacter pour cela",
	"dGhlIHRlY2huaWNpYW4gaGFzIGVhc3kgYWNjZXNzIHRvIHlvdXIgYm9pbGVyIChyZW1vdmUgYW55IG9ic3RhY2xlcyBpZiBuZWVkZWQpIGFuZCB0aGUgaW50ZXJ2ZW50aW9uIGNhbiBiZSBleGVjdXRlZCBzYWZlbHk=":"le technicien peut accéder facilement à votre chaudière (enlevez tout obstacle éventuel) et l'intervention peut se dérouler en toute sécurité",
	"b3VyIHRlY2huaWNpYW4gaGFzIHRoZSBvcHRpb24gdG8gcGFyayBuZWFyYnkuIEl0IGlzIGJlc3QgdG8gdGFrZSBpbnRvIGFjY291bnQgbWFya2V0IGRheXMsIGZhaXJzLCByb2FkIHdvcmtzLC4uLg==":"notre technicien a la possibilité de stationner à proximité. Il est préférable que vous teniez compte des jours de marché, des foires, des travaux de voirie,...",
	"T0s=":"OK",
	"SSBnZXQgaXQ=":"J'ai compris",
	"UGxlYXNlIG1ha2Ugc3VyZSB0aGF0":"Veuillez-vous assurer que :",
	"QmFjaw==":"Retour",
	"QXR0ZW50aW9uIQ==":"Attention !",
	"WW91IGFyZSBhYm91dCB0byBpbmZvcm0gdXMgdGhhdCBpdCBpcyBub3QgYSBnYXMgaW5zdGFsbGF0aW9u":"Vous êtes sur le point de nous informer qu'il ne s'agit pas d'une installation sur gaz",
	"WW91IGFyZSBhYm91dCB0byBpbmZvcm0gdXMgdGhhdCBpdCBpcyBub3QgYSBmdWVsIGluc3RhbGxhdGlvbg==":"Vous êtes sur le point de nous informer qu'il ne s'agit pas d'une installation sur mazout",
	"WW91ciBhcHBvaW50bWVudCB3aWxsIGJlIGNhbmNlbGxlZCBpZiB5b3UgY29uZmlybQ==":"Si vous confirmez, votre rendez-vous sera annulé"
};