import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Helpers from './../../services/Helpers.js';
import CustomProposal from './../../components/CustomProposal.js';
import Confirmed from './../../components/Confirmed.js';
import Modal from 'react-bootstrap/Modal';

class Main extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	proposal:{
	      		'already_replanned': true
	      	},
	      	show_content: false,
	      	show_postpone: true,
	      	show_more_info: false,
	      	step: 2
	  	};
	}

	componentDidMount(){
		Helpers.doRequest('get','intervention/intervention')
            .then(data=>{   
                let oldState=Object.assign({}, this.state);	 
                oldState.show_content=true;
                oldState.proposal=data.proposal;
                const utm_source_param_val = Helpers.getUrlParam('utm_source');
                if(utm_source_param_val === 'uda' || utm_source_param_val === 'web'){
                	oldState.show_postpone=false;
                }
                if(data.last_opened_diff){
                	oldState.show_more_info=true;
                }             
            	this.setState(oldState);
            }).catch(error=>{
            	this.props.history.push('/');
            });
	}

	refreshData(data){
		let oldState=Object.assign({}, this.state);	
		oldState.proposal=data.proposal;
		oldState.step=4;
		this.setState(oldState);		
	}

	confirmProposal(){
		Helpers.doRequest('post','intervention/proposals',{'confirm':'1','planeddate':new Date(this.state.proposal.custom_date.original_planeddate*1000),'startdate':this.state.proposal.custom_date.original_startdate})
            .then(resp=>{   
				let oldState=Object.assign({}, this.state);	
				oldState.step=4;
				this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,false,true);
            });	
	}

	render(){
		const { t }=this.props;
		let sub_container_class="overflow-auto mt-2 mh-100";
		if((this.state.step > 1 && this.state.step < 3) || this.state.step === 4){
			sub_container_class+=" col-12 px-0 flex-column d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-baseline";
		}else if(this.state.step === 3){
			sub_container_class+=" col-12 px-0 flex-column d-md-flex flex-md-row justify-content-center align-items-md-center";
		}
		let left_box_class="";
		if(this.state.step === 1){
			left_box_class=" px-5";
		}else if(this.state.step > 1 && this.state.step < 3){
			left_box_class=" px-5 col-lg-4";
		}else if(this.state.step === 3){
			left_box_class=" col-lg-4 py-4 px-5 mr-5";
		}else if(this.state.step > 3){
			left_box_class=" col-lg-4 px-5 pb-4 pt-4";
		}
		return (
			<div className="container">
				{this.state.show_content && <div className="cozie--content-wrap d-flex justify-content-center align-items-center">
					<div className={sub_container_class}>
						{this.state.step < 5 && <div className={"card bg-white d-inline-block"+left_box_class}>
				          {this.state.step < 4 && !this.state.proposal.fls_escalated && <div className="headings-800 ff-medium text-gradient strong- link- mt-5 px-0 cozie--title">
				          	{this.state.proposal.already_replanned ? t(Helpers.encodeWord('Your confirmed appoinment')) : (this.state.proposal.is_replan ? t(Helpers.encodeWord('your appointment proposal')) : t(Helpers.encodeWord('Plan your appointment')))}
				          </div>}
				          <div className="body-300 ff-news text-cozie-text-grey strong- link- my-4 col-12 px-0">
				          	{this.state.step < 3 && !this.state.proposal.already_replanned && !this.state.proposal.fls_escalated && this.state.proposal.is_replan && t(Helpers.encodeWord('you have an unconfirmed appointment for'))}
				          	{this.state.step === 3 && t(Helpers.encodeWord('Your appointment has been confirmed for'))}
				          	{this.state.step < 3 && !this.state.proposal.is_replan && t(Helpers.encodeWord('You can self-plan your "Maintenance" intervention by selecting one of the proposed dates in the screen on the right. We will ask you to confirm your personal information in the next step.'))}
				          </div>
				          {((!this.state.proposal.fls_escalated && this.state.proposal.is_replan) || this.state.step > 3) && <div className="body-400 ff-medium text-blue strong- link- px-0 mb-3">{t(Helpers.encodeWord(this.state.proposal.planned_day))} {this.state.proposal.planned_date}</div>}
				          {((!this.state.proposal.fls_escalated && this.state.proposal.is_replan) || this.state.step > 3) && <div className="body-400 ff-medium text-blue strong- link- px-0">{t(Helpers.encodeWord('between'))} {this.state.proposal.planned_start_time} - {this.state.proposal.planned_end_time}</div>}
				          {this.state.step < 3 && !this.state.proposal.already_replanned && !this.state.proposal.fls_escalated && this.state.proposal.is_replan && <input type="button" name="" value={t(Helpers.encodeWord('Confirm this appointment'))}
				            className='single--orange--reversed w-auto mr-auto my-4 py-3 px-5 ff-news body-200' onClick={()=>this.confirmProposal()} />}
				          {this.state.step === 1 && !this.state.proposal.already_replanned && <div className="links-300 ff-news text-blue strong- link- mb-5 col-12 px-0 text-underline cursor-pointer fs-1-5" onClick={()=>Helpers.updateStateValue(this,2,'step')}>
				            {t(Helpers.encodeWord('Select date'))}
				           </div>}
				           {this.state.step < 3 && this.state.proposal.fls_escalated && <div className="body-400 ff-medium text-blue strong- link- px-0 mb-3">{t(Helpers.encodeWord('Unfortunately your previously proposed date is no longer available. Please choose a different time for your boiler maintenance.'))}</div>}
						</div>}
						{this.state.step === 2 && <CustomProposal data={this.state.proposal.custom_date} t={t} is_replan={this.state.proposal.is_replan} show_postpone={this.state.show_postpone}
							confirmChange={(d)=>this.refreshData(d)} postponed={()=>Helpers.updateStateValue(this,7,'step')}/>
						}
						{this.state.step === 3 && <div className="card bg-white col-lg-4 p-5">
				            <div className="d-flex justify-content-center align-items-center">
				              <span className="f-icon f-icon-impulse-validate text-blue f-icon-large"></span>
				            </div>
				            <div className="body-300 ff-news text-cozie-text-grey strong- link- px-0 mt-4">{t(Helpers.encodeWord('Your order for'))} {t(Helpers.encodeWord(this.state.proposal.planned_day))} {t(Helpers.encodeWord('between'))} {this.state.proposal.planned_start_time} - {this.state.proposal.planned_end_time} {t(Helpers.encodeWord('was confirmed'))}!</div>
				        </div>}
						{this.state.step === 4 && <Confirmed t={t} 
							proposal={this.state.proposal} 			
							confirmInformation={()=>Helpers.updateStateValue(this,5,'step')}
							cancelProposal={()=>Helpers.updateStateValue(this,6,'step')}
						/>}			
						{this.state.step === 5 && <div className="card bg-white p-5">
				          <div className="d-flex justify-content-center align-items-center">
				            <span className="f-icon f-icon-impulse-like text-blue f-icon-large"></span>
				          </div>
				          <div className="body-300 ff-news text-cozie-text-grey strong- link- my-5 col-12 px-0">{t(Helpers.encodeWord("We'll see you"))}</div>
				          <div className="body-400 ff-medium text-blue strong- link- px-0 mb-3">{t(Helpers.encodeWord(this.state.proposal.planned_day))} {this.state.proposal.planned_date}</div>
				          <div className="body-400 ff-medium text-blue strong- link- px-0">{t(Helpers.encodeWord('between'))} {this.state.proposal.planned_start_time} - {this.state.proposal.planned_end_time}</div>
				        </div>}
						{this.state.step === 6 && <div className="card bg-white p-5">
					          <div className="d-flex justify-content-center align-items-center">
					            <i className="far fa-calendar-times text-danger fs-150"></i>
					          </div>
					          <div className="body-400 ff-medium text-danger strong- link- px-0 mb-3">{t(Helpers.encodeWord('Your appointment proposal for'))} {this.state.proposal.planned_date} {t(Helpers.encodeWord('got cancelled'))}.</div>
					          <div className="body-400 ff-medium text-danger strong- link- px-0">{t(Helpers.encodeWord('Engie will contact you shortly'))}.</div>
					          <div className="body-400 ff-medium text-danger strong- link- px-0">{t(Helpers.encodeWord('Questions'))}? {t(Helpers.encodeWord('Call'))} Engie {t(Helpers.encodeWord('on'))} 078/35.33.34</div>
					    </div>}
					    {this.state.step === 7 && <div className="card bg-white p-5">
				          <div className="d-flex justify-content-center align-items-center">
				            <span className="f-icon f-icon-impulse-like text-blue f-icon-large"></span>
				          </div>
				          <div className="body-400 ff-news text-cozie-text-grey strong- link- my-5 col-12 px-0">{t(Helpers.encodeWord("Your maintenance has been postponed"))}</div>
				        </div>}
					</div>
				</div>}
				<Modal
					show={this.state.show_more_info}
			      	size="md"
			      	aria-labelledby="info-contained-modal-label"
			      	centered
			      	backdrop="static"
			      	keyboard={false}
			      	onHide={()=>Helpers.updateStateValue(this,false,'show_more_info')}
			    >
				    <Modal.Body>
				    	<div className="col-12 p-0 my-3">
			                <div className="headings-800 ff-medium text-gradient strong- link- mt-5 px-0 cozie--title">
				          		{t(Helpers.encodeWord('Avoid useless costs'))}
				          	</div>
				          	<div className="body-200 ff-news text-cozie-text-grey strong- link- my-4 col-12 px-0">
				          		<p className="body-300">{t(Helpers.encodeWord('Please make sure that'))}</p>	
				          		<ul>
					          		<li className="mb-3">
					          			{t(Helpers.encodeWord('if your boiler does not work or displays error messages, it has to be repaired first. You can contact us for this'))};
					          		</li>
					          		<li className="mb-3">
					          			{t(Helpers.encodeWord('the technician has easy access to your boiler (remove any obstacles if needed) and the intervention can be executed safely'))};
					          		</li>
					          		<li>
					          			{t(Helpers.encodeWord('our technician has the option to park nearby. It is best to take into account market days, fairs, road works,...'))}
					          		</li>
				          		</ul>
				          	</div>
			            </div>
				    </Modal.Body>
				    <Modal.Footer className="justify-content-center">
				    	<div className="cozie--blue-buttons-wrap d-flex">
				    	  <input type="button" name="" value={this.props.t(Helpers.encodeWord('I get it'))}
	                        className="single--orange mr-1 px-4 py-3 d-flex ff-news body-200" onClick={()=>Helpers.updateStateValue(this,false,'show_more_info')} />
			            </div>
		      		</Modal.Footer>
			    </Modal>	
		   	</div>
		);
	}

}
export default withRouter(withTranslation()(Main));