import Helpers from './Helpers.js';
import { isValidPhoneNumber } from 'libphonenumber-js';

const FormValidation={

	checkValidity(rules,state,propertyTranslations,translator,noTranslation){
		let responseObj={'has_errors':false,'errors':{}};
		for(let x in rules){
			for(let y in rules[x]){		
				let error_masg=this.errorMessage(rules[x][y],x,state,propertyTranslations,translator,noTranslation);
                if(error_masg){
					if(!responseObj.has_errors){
	                    responseObj.has_errors=true;
	                }
	                if(!responseObj.errors[x]){
	                	responseObj.errors[x]=[];
	                }
	                responseObj.errors[x].push(error_masg);
					break;
				}
			}
		}
		return responseObj;
    },

    errorMessage(type,name,state,propertyTranslations,translator,noTranslation){
        let msg="";
        switch(true){
            case /required/.test(type):
            	if(!state[name]){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field is required' : translator(Helpers.encodeWord('field is required')));
            	}                
                break;
            case /float/.test(type):
            	if(isNaN(state[name])){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field needs to be a number' : translator(Helpers.encodeWord('field needs to be a number')));
            	}
            	break;
            case /not_empty/.test(type):
            	if(!state[name].length){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field is required' : translator(Helpers.encodeWord('field is required')));
            	}
            	break;
            case /email/.test(type):
                let regTest=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(!regTest.test(state[name])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field must be a valid email address' : translator(Helpers.encodeWord('field must be a valid email address')));
                }
                break;
            case /min/.test(type):
                let splittedMin=type.split(":");
                if(state[name] && parseInt(state[name].length)<parseInt(splittedMin[1])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field must be at least '+splittedMin[1]+' characters' : translator(Helpers.encodeWord('field must be at least'))+" "+splittedMin[1]+" "+translator(Helpers.encodeWord('characters')));
                }
                break;
            case /max/.test(type):
                let splittedMax=type.split(":");
                if(state[name] && parseInt(state[name].length)>parseInt(splittedMax[1])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field may not be greater than '+splittedMax[1]+' characters' : translator(Helpers.encodeWord('field may not be greater than'))+" "+splittedMax[1]+" "+translator(Helpers.encodeWord('characters')));
                }
                break;
            case /phone/.test(type):
                if(!isValidPhoneNumber(state[name], 'BE')){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field must be a valid phone' : translator(Helpers.encodeWord('field must be a valid phone')));
                }
                break;
            default:
        }
        return msg;
    }

}
export default FormValidation;