import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en_trans } from './../util/translations/en.js';
import { fr_trans } from './../util/translations/fr.js';
import { nl_trans } from './../util/translations/nl.js';
import Store from './Store.js';

i18n.use(initReactI18next).init({
  	lng: Store.retrieveLanguage(),
  	fallbackLng: 'en',
  	resources: {
    	en: {
      		translation: en_trans,
    	},
    	fr: {
      		translation: fr_trans,
    	},
    	nl: {
      		translation: nl_trans,
    	},
  	},
});
export default i18n;