import React from 'react';
import './App.css';
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Store from './services/Store.js';
import Header from './pages/General/Header';
import Login from './pages/Login/Login';
import Main from './pages/General/Main';
import Notfound from './pages/General/Notfound';
import Confirm from './pages/General/Confirm';
import i18n from "./services/I18n";

const eventEmitter = require('events');
window.ee = new eventEmitter();

class App extends React.Component{
  
  constructor(props) {
      super(props);
      this.state = {
        isLoged:Store.retrieveStatus(),
        code:Store.retrieveAccessToken()
      };
      this.listenerData=[];
  }

  componentDidMount() {
    if(this.listenerData.indexOf("storeChange") === -1){
      window.ee.on('storeChange', () => { 
        let oldState=Object.assign({}, this.state);
        oldState.isLoged=Store.retrieveStatus();
        this.setState(oldState);
      });
    }     
    const { history } = this.props;
    history.listen((newLocation, action) => {
      if (action === "POP") {
        history.go(1);
      } 
    }); 
  }

  componentWillUnmount(){
    window.onpopstate = null;
    if(this.listenerData.indexOf("storeChange") !== -1){
      window.ee.removeListener('storeChange', () => { 
        //
      });
    }   
  }

  render(){ 
    return (
      <div className="App">
          <Header />
          <div className="cozie bg-cozie-grey">
              <Switch>
                <Route exact path="/int/:id" render={() => <Login /> }></Route>
                <Route exact path="/int/:id/main" render={() => (this.state.isLoged ? <Main /> : <Redirect to='/' />) }></Route>
                <Route exact path="/int/:id/confirm" render={() => <Confirm /> }></Route>
                <Route exact path="/" render={() => <Notfound /> }></Route>
                <Route render={() => <Notfound /> }></Route>          
            </Switch>
          </div>         
      </div>
    )
  }
}
export default withRouter(App);
