import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import CookieBar from './../../services/CookieBar.js';

class Confirm extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	show_content: false
	  	};
	}

	componentDidMount(){		
		Helpers.doRequest('post','intervention/confirm')
            .then(data=>{   
            	Store.updateCookiesAccepted(data.accepted_cookies);
            	if(Store.retrieveLanguage() !== data.language){
            		Store.updateLanguage(data.language);
            		this.props.i18n.changeLanguage(data.language);
            	}          	
                let oldState=Object.assign({}, this.state);	 
                oldState.show_content=true;
                oldState.proposal=data.proposal;
            	this.setState(oldState);
            	if(!Store.retrieveCookiesAccepted()){
					CookieBar.init();
				}
            }).catch(error=>{
            	this.props.history.push('/int/'+Helpers.getId());
            });
	}

	selectDate(){
		this.props.history.push('/int/'+Helpers.getId());
	}

	render(){
		const { t }=this.props;
		return (
			<div className="container">
				{this.state.show_content && <div className="cozie--content-wrap d-flex justify-content-center align-items-center">
					<div>
						<div className="card bg-white p-5">
				          <div className="headings-800 ff-medium text-gradient strong- link- mt-5 px-0 cozie--title">
				          	{t(Helpers.encodeWord('your appointment proposal'))}
				          </div>
				          <div className="body-300 ff-news text-cozie-text-grey strong- link- my-4 col-12 px-0">
				          	{t(Helpers.encodeWord('Your appointment has been confirmed for'))}
				          </div>
				          <div className="body-400 ff-medium text-blue strong- link- px-0 mb-3">{t(Helpers.encodeWord(this.state.proposal.planned_day))} {this.state.proposal.planned_date}</div>
				          <div className="body-400 ff-medium text-blue strong- link- px-0">{t(Helpers.encodeWord('between'))} {this.state.proposal.planned_start_time} - {this.state.proposal.planned_end_time}</div>
				          <div className="links-300 ff-news text-blue strong- link- mb-5 col-12 px-0 text-underline cursor-pointer fs-1-5 mt-4" onClick={()=>this.selectDate()}>
				            {t(Helpers.encodeWord('Select date'))}
				           </div>
						</div>
					</div>
				</div>}		
		   	</div>
		);
	}

}
export default withRouter(withTranslation()(Confirm));