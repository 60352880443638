import axios from 'axios';
import Store from './Store';
import { encode as btoa } from 'base-64';

const Helpers={
	state: {
		url_params:{}
	},
	doRequest(method,url,data,withoutLoading) {
		let baseURL = process.env.REACT_APP_API_URL;
		let loadingElement;
		if(!withoutLoading){
			loadingElement=document.getElementById('loading_wrap');
		}	
		if(loadingElement){
			loadingElement.classList.remove("hidden");
		}		
		let headers={};
		let processData=true;
		if (data && data.__proto__.constructor.name === 'FormData') {
			processData=false;
            headers = {
                "content-type": undefined
            }
        }else{
        	headers={
				"content-type":"application/json"
			};
        }
        if(url.indexOf('auth') === -1){
        	let token=Store.retrieveAccessToken();
        	if(!token){
        		token=this.getId();
        	}
			headers.q=token;
		}else{
			headers.q=this.getId();
		}      
        if(!data){
        	data={};
        }
        return new Promise((resolve, reject)=>{
        	axios({
			  	method:method,
			  	url:baseURL+url,
			  	data:data,
			  	processData: processData,
			  	headers:headers
			}).then(response=>{
				if(loadingElement){
					loadingElement.classList.add("hidden");
				}	
			  	resolve(response.data.data);
			}).catch(error=>{
				if(loadingElement){
					loadingElement.classList.add("hidden");
				}
				if(error.response && error.response.status===401){
					Store.clearData();
					window.ee.emit('storeChange');
				}
			  	reject(error.response.data);
			});
        });
	},
	showAlerts(scope,input,formObj,noTranslation){
		let oldState=Object.assign({}, scope.state);
		if(!oldState.alerts){oldState.alerts=[]};
		if(!oldState.alertsField){oldState.alertsField=[]};
		oldState.errors={};
		oldState.success={};
		oldState.alerts=[];
		oldState.alertsField=[];
		if(input.errors){
			if(input.errors.error){
				let alerts=[];
				alerts.push({
					type: 'danger',
	                msg: noTranslation ? input.errors.error : scope.props.t(this.encodeWord(input.errors.error), input.errors.error)
				});
				oldState.alerts=alerts;		
			}else{
				let alerts=[];
				for(let x in input.errors){
					if(formObj){
						oldState.alertsField[x]=noTranslation ? input.errors[x] : scope.props.t(this.encodeWord(input.errors[x]), input.errors[x]);
						oldState.errors[x]=true;
					}else{
						alerts.push({
							type: 'danger',
			                msg: noTranslation ? input.errors[x] : scope.props.t(this.encodeWord(input.errors[x]), input.errors[x])
						});
					}				
				}
			}
		}else if(input.success){
			if(input.success.success){
				let alerts=[];
				alerts.push({
					type: 'success',
	                msg: noTranslation ? input.success.success : scope.props.t(this.encodeWord(input.success.success), input.success.success)
				});
				oldState.alerts=alerts;			
			}else{
				for(let x in input.success){
					if(formObj){
						oldState.alertsField[x]=noTranslation ? input.success[x] : scope.props.t(this.encodeWord(input.success[x]), input.success[x]);
						oldState.success[x]=true;
					}				
				}
			}
		}
		scope.setState(oldState);
	},
	closeAlert(scope,index){
		let oldState=Object.assign({}, scope.state);
		oldState.alerts.splice(index,1);
		scope.setState(oldState);
	},
	showLoading(){
		let loadingElement=document.getElementById('loading_wrap');
		if(loadingElement){
			loadingElement.classList.remove("hidden");
		}
	},
	stopLoading(){
		let loadingElement=document.getElementById('loading_wrap');
		if(loadingElement){
			loadingElement.classList.add("hidden");
		}
	},
	submitForm(scope,e,functionName){
		if(e.key === "Enter" && functionName){
			e.preventDefault();
			scope[functionName]();
		}
	},
	updateStateValue(scope,value,model,parent){
        let oldState=Object.assign({}, scope.state);      
        if(parent){
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[parent][model[x]]=value[x];
        		}
        	}else{
        		oldState[parent][model]=value;
        	}     	   	   	     	
        }else{
        	if(Array.isArray(model)){
        		for(let x in model){
        			oldState[model[x]]=value[x];
        		}
        	}else{
        		oldState[model]=value;
        	}       	
        }  
        oldState.errors={};
        scope.setState(oldState);  
    },
    encodeWord(word){
		return btoa(word);
	},
	getId(){
		let id="";
		let pathName=window.location.pathname;
        pathName=pathName.replace("/int/","");
        if(pathName.indexOf("/") === -1 && pathName.indexOf("?") === -1){
        	id=pathName;
        }else{
        	let slash_char=pathName.indexOf("/");
        	let question_char=pathName.indexOf("?");
        	if(question_char === -1){
        		id=pathName.substring(0,slash_char);
        	}else if(slash_char === -1){
        		id=pathName.substring(0,question_char);
        	}else{
        		id = slash_char < question_char ? pathName.substring(0,slash_char) : pathName.substring(0,question_char);
        	}  	
        }
        return id;
	},
	getMonthName(index){
		const monthNames = ["January", "February", "March", "April", "May", "June",
		  "July", "August", "September", "October", "November", "December"
		];
		return monthNames[index];
	},
	translateHtml(str, translator){
        let needle = "{l}";
        let needle_end = "{endl}";
        let position_s=[];
        let position_e =[];
        let raw_data=[];
        let final_data=[];
        let initPos = 0;    
        while ((initPos = str.indexOf(needle,initPos)) > -1) {
            let lastPos=str.indexOf(needle_end,initPos);
            position_s.push(initPos + needle.length);
            position_e.push(lastPos);
            initPos += needle.length;
        }
        if(position_s.length){
        	for(let x in position_s) {    
	            raw_data.push(str.substring(position_s[x],position_e[x]));
	        }
	        for(let x in raw_data){
	        	final_data[raw_data[x]]=translator(this.encodeWord(raw_data[x]));
	        }
        }       
        let string=str;
        for(let x in final_data){
            string=string.replace(new RegExp("{l}"+x+"{endl}", 'g'),final_data[x]);
        }
        return string;
    },
    setUrlParams(){
    	this.state.url_params = new URLSearchParams(window.location.search);
    },
    getUrlParam(name){
    	return (this.state.url_params.get(name) ? this.state.url_params.get(name) : '')
    }
}
export default Helpers;