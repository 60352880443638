import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Helpers from './../../services/Helpers.js';

class Notfound extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	msg: "Oops! Page not found!",
	      	show_content: false
	  	};
	}

	componentDidMount(){		
		if(this.props.location.state && this.props.location.state.msg){
        	let msg=Object.assign({}, this.props.location.state.msg);
        	window.history.pushState(null, '');
        	let oldState=Object.assign({}, this.state);	 
            oldState.show_content=true;
            oldState.msg=msg.data;
        	this.setState(oldState);
        }else{
        	this.setState({'show_content':true});
        }
	}

	render(){
		return (
			<div className="container">
		      <div className="cozie--content-wrap d-md-flex justify-content-md-center align-items-md-center">
		        <div className="card bg-white col-12 col-lg-4 p-5">
		          <div className="d-flex justify-content-center align-items-center">
		            <span className="f-icon f-icon-impulse-cross text-cozie-text-red f-icon-large"></span>
		          </div>
		          <div className="body-300 ff-news text-cozie-text-red strong- link- mt-3 col-12 px-0 text-center">
		            {(this.state.show_content ? Helpers.translateHtml(this.state.msg, this.props.t) : '')}
		          </div>
		          <div className="body-300 ff-news text-cozie-text-red strong- link- mt-3 col-12 px-0 text-center">
		            Contact: 078 35 33 34
		          </div>
		        </div>
		      </div>
		    </div>
		);
	}

}
export default withRouter(withTranslation()(Notfound));