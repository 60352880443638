import React from 'react';
import Helpers from './../services/Helpers.js';
import Form from 'react-bootstrap/Form';
import FormValidation from './../services/FormValidation.js';
import Modal from 'react-bootstrap/Modal';

class Confirmed extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	proposal:this.props.proposal,
	      	showNotice:false,
	      	show_cancel_proposal_modal: false
	  	};
	}

	cancelProposal(cancel_reason_id){
		Helpers.doRequest('post','intervention/proposals',{'fls_replan':'1','status':'4','mark_cancelled':'1','cancel_reason_id':cancel_reason_id})
            .then(data=>{   
            	this.props.cancelProposal();
            }).catch(error=>{
            	Helpers.showAlerts(this,error,false,true);
            });		
	}

	confirmInformation(){
		if(!this.state.proposal.email && !this.state.proposal.cell){
			Helpers.showAlerts(this,{'errors':{'error':"The email field OR phone number needs to be filled"}});
			return false;
		}
		let validations={};
		let fieldTranslations={};
		if(this.state.proposal.cell && this.state.proposal.cell.length){
			fieldTranslations.cell=this.props.t(Helpers.encodeWord('This'));
			validations.cell=["required","phone"];
		}
		if(this.state.proposal.email && this.state.proposal.email.length){
			fieldTranslations.email=this.props.t(Helpers.encodeWord('This'));
			validations.email=["required","email"];
		}
		let formErrors=FormValidation.checkValidity(validations,this.state.proposal,fieldTranslations,this.props.t);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true,true);
			return false;
		}
		let tmp_obj=Object.assign({}, this.state.proposal);
		Helpers.doRequest('post','intervention/contact_details',tmp_obj)
	        .then(resp=>{   
	            this.props.confirmInformation();
	        }).catch(error=>{
	        	Helpers.showAlerts(this,error,false,true);
	        });
	}

	updateFields(value,model){
		let oldState=Object.assign({}, this.state);	
		oldState.proposal[model]=value;
		if(oldState.proposal[model+"_validate"] !== value){
			oldState.showNotice=true;
		}
		this.setState(oldState);
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
			<div className="d-flex p-2 bg-cozie-red mb-5" key={key}>
              <div className="body-200 ff-news text-white strong- link- col-12 px-0 py-3">{item.msg}</div>
              <span className="f-icon f-icon-close text-white f-icon-small" onClick={() => Helpers.closeAlert(this,key)}></span>
            </div>
	    );
		return (
			<div className="card bg-white col-12 col-lg-7 p-5">
				{generalMsg}
	            <div className="headings-800 ff-medium text-gradient strong- link- px-0 cozie--title">{this.props.t(Helpers.encodeWord('Just this'))}...</div>
	            <div className="body-300 ff-news text-cozie-text-grey strong- link- my-4 col-12 px-0">{this.props.t(Helpers.encodeWord('Before you can confirm your appointment, we ask you to check your details and correct them where possible and necessary'))}.</div>
	            <div className="d-flex justify-content-start align-items-center">
	              <div className="body-300 ff-news text-cozie-text-grey strong- link- my-4 flex-grow px-0">{this.props.t(Helpers.encodeWord('Address of the installation'))}:</div>
	              <div className="body-300 ff-medium text-cozie-text-grey strong- link- my-4 col-7"> {this.state.proposal.installation_address}</div>
	            </div>
	            <div className="d-flex justify-content-start align-items-center">
	              <div className="body-300 ff-news text-cozie-text-grey strong- link- my-4 flex-grow px-0">{this.props.t(Helpers.encodeWord('Installation data'))}:</div>
	              <div className="body-300 ff-medium text-cozie-text-grey strong- link- my-4 col-7"> {this.props.t(Helpers.encodeWord(this.state.proposal.installation_type))}</div>
	            </div>
	            <div className="col-12 px-0 mb-5">
	              <Form.Label className={"col-form-label"+(this.state.errors.cell ? " col-form-label--error": "")}>{this.props.t(Helpers.encodeWord('Mobile phone'))}</Form.Label>
	              <Form.Control
	                    className={this.state.errors.cell ? "form-control--error": ""}
				    	value={this.state.proposal.cell || ''} 
				    	name="cell" 
				    	placeholder={this.props.t(Helpers.encodeWord('Mobile phone'))} 
				    	autoComplete="off"
				    	onChange={(e)=>this.updateFields(e.target.value,'cell')}
				    	onKeyPress={(e) => Helpers.submitForm(this,e,'') }/>
				    {this.state.errors.cell && <p className="text-danger mt-1">{this.state.alertsField.cell}</p>}
	            </div>
	            <div className="col-12 px-0 mb-5">
	              <Form.Label className={"col-form-label"+(this.state.errors.email ? " col-form-label--error": "")}>{this.props.t(Helpers.encodeWord('E-mail address'))}</Form.Label>
	              <Form.Control
	              		className={this.state.errors.email ? "form-control--error": ""}
				    	value={this.state.proposal.email || ''} 
				    	name="email" 
				    	placeholder={this.props.t(Helpers.encodeWord('E-mail address'))} 
				    	autoComplete="off"
				    	onChange={(e)=>this.updateFields(e.target.value,'email')}
				    	onKeyPress={(e) => Helpers.submitForm(this,e,'') }/>
				    {this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
	            </div>
	            {this.state.showNotice && <div className="body-100 ff-news text-cozie-text-grey strong- link- mt-3 mb-5 col-12 px-0">{this.props.t(Helpers.encodeWord('It appears that you have changed one or more of your contact details'))}. {this.props.t(Helpers.encodeWord('This adjustment only applies to this one intervention'))}. {this.props.t(Helpers.encodeWord('We recommend that you contact Engie on 078 353 780 to permanently adjust your data'))}</div>}
	            <div className="cozie--blue-buttons-wrap d-flex">
	              <input type="button" name="" value={this.props.t(Helpers.encodeWord('The information above is correct'))}
	                className='single--green--reversed py-3 px-4 mb-6 d-flex ff-news body-200' onClick={()=>this.confirmInformation()} />
	            </div>
	            <div className="cozie--blue-buttons-wrap d-flex flex-wrap">
	              <input type="button" name="" value={(this.state.proposal.installation_type === 'Gas' ? this.props.t(Helpers.encodeWord('It is not a gas installation')) : this.props.t(Helpers.encodeWord('It is not a fuel installation')))}
	                className="cozie--blue-buttons-wrap--red body-100 ff-news py-3 px-4 mr-3" onClick={()=>Helpers.updateStateValue(this,true,'show_cancel_proposal_modal')} />
	              <input type="button" name="" value={this.props.t(Helpers.encodeWord('The address is not (completely) correct'))}
	                className="cozie--blue-buttons-wrap--red body-100 ff-news py-3 px-4 mr-3 hidden" onClick={()=>this.cancelProposal(this.state.proposal.cancel_reason_address)} />
	            </div>
	            <Modal
					show={this.state.show_cancel_proposal_modal}
			      	size="md"
			      	aria-labelledby="contained-modal-label"
			      	centered
			      	backdrop="static"
			      	onHide={()=>Helpers.updateStateValue(this,false,'show_cancel_proposal_modal')}
			    >
				    <Modal.Body>
				    	<div className="col-12">
				    		<button type="button" className="btn-close float-right" onClick={()=>Helpers.updateStateValue(this,false,'show_cancel_proposal_modal')}></button>
				    	</div>
				    	<div className="col-12 p-0 my-3">
			                <div className="justify-content-center headings-800 ff-medium text-gradient strong- link- mt-5 px-0 cozie--title d-flex">
				          		{this.props.t(Helpers.encodeWord('Attention!'))}
				          	</div>
				          	<div className="body-200 ff-news text-cozie-text-grey strong- link- my-4 col-12 px-0">
				          		{this.state.proposal.installation_type === 'Gas' && <p className="body-300">{this.props.t(Helpers.encodeWord('You are about to inform us that it is not a gas installation'))}.</p>}
				          		{this.state.proposal.installation_type !== 'Gas' && <p className="body-300">{this.props.t(Helpers.encodeWord('You are about to inform us that it is not a fuel installation'))}.</p>}
				          		<p className="body-300">{this.props.t(Helpers.encodeWord('Your appointment will be cancelled if you confirm'))}.</p>
				          	</div>
			            </div>
				    </Modal.Body>
				    <Modal.Footer>
				    	<div className="cozie--blue-buttons-wrap w-100">
				    	  <input type="button" name="" value={this.props.t(Helpers.encodeWord('Back'))}
	                        className="cozie--blue-buttons-wrap--blue mr-1 px-4 py-3 ff-news body-200 ml-1" onClick={()=>Helpers.updateStateValue(this,false,'show_cancel_proposal_modal')} />
			              <input type="button" name="" value={(this.state.proposal.installation_type === 'Gas' ? this.props.t(Helpers.encodeWord('It is not a gas installation')) : this.props.t(Helpers.encodeWord('It is not a fuel installation')))}
	                		className="cozie--blue-buttons-wrap--red body-100 ff-news py-3 px-4 mr-2 float-right" onClick={()=>{Helpers.updateStateValue(this,false,'show_cancel_proposal_modal');this.cancelProposal(this.state.proposal.cancel_reason_installation_type)}} />
			            </div>
		      		</Modal.Footer>
			    </Modal>
	        </div>
		);
	}

}
export default Confirmed;