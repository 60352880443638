import React from 'react';
import Helpers from './../services/Helpers.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker,{ registerLocale } from "react-datepicker";
import DatePickerInput from './DatePickerInput.js';
import Store from './../services/Store.js';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import nlBE from 'date-fns/locale/nl-BE';

registerLocale('en', enUS)
registerLocale('fr', fr)
registerLocale('nl', nlBE)

class CustomProposal extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	custom_proposal:{
	      		'proposals':[]
	      	},
	      	timeslots:this.props.data.timeslots,
	      	next_period_check:false,
	      	next_month_check:false,
	      	next_month_show:false,
	      	custom_date_proposal_show:false,
	      	custom_select:false,
	      	proposal_selected:false,
	      	disabled_select_proposal:false,
	      	advanced_search_disabled:true,
	      	days:[
	      		{  id: 1, 'name': 'Mon' },
	      		{  id: 2, 'name': 'Tue' },
	      		{  id: 3, 'name': 'Wed' },
	      		{  id: 4, 'name': 'Thu' },
	      		{  id: 5, 'name': 'Fri' },
	      	],
	      	minStartDate:new Date().setDate(new Date().getDate()+2),
	      	minEndDate:new Date().setDate(new Date().getDate()+3),
	      	show_postpone_modal:false,
	      	postpone_data:{
	      		'options': [],
	      		'selected':""
	      	}
	  	};
	}

	componentDidMount(){
		if(this.props.is_replan){
			let dateFrom=new Date(this.props.data.dateFrom*1000);
	        let dateTo=new Date(this.props.data.dateTo*1000);
	        if(dateFrom.getTime() > dateTo.getTime()){
	        	let error_msg="{l}There are no available slots in the next few days. Please search in the following period{endl}";
	            Helpers.showAlerts(this,{'errors':{'error':error_msg}},false,true);
	        }else{
	        	Helpers.doRequest('post','intervention/proposals',{'fls_replan':'1','status':'1','dateFrom':dateFrom,'dateTo':dateTo})
	            .then(data=>{   
	                let oldState=Object.assign({}, this.state);	 
	                oldState.custom_proposal=data;
	            	this.setState(oldState);
	            	if(!data.proposals.length){        		
	            		//let error_msg="{l}No available slots found between{endl} "+dateFrom.getDate()+" {l}"+Helpers.getMonthName(dateFrom.getMonth())+"{endl} "+dateFrom.getFullYear()+" {l}and{endl} "+dateTo.getDate()+" {l}"+Helpers.getMonthName(dateTo.getMonth())+"{endl} "+dateTo.getFullYear();
	            		let error_msg="{l}There are no available slots in the next few days. Please search in the following period{endl}";
	            		Helpers.showAlerts(this,{'errors':{'error':error_msg}},false,true);
	            	}
	            }).catch(error=>{
	            	//Helpers.showAlerts(this,{'errors':{'error':"There was an error while retrieving proposals. Try again"}});
	            	//let error_msg="{l}No available slots found between{endl} "+dateFrom.getDate()+" {l}"+Helpers.getMonthName(dateFrom.getMonth())+"{endl} "+dateFrom.getFullYear()+" {l}and{endl} "+dateTo.getDate()+" {l}"+Helpers.getMonthName(dateTo.getMonth())+"{endl} "+dateTo.getFullYear();
	            	let error_msg="{l}There are no available slots in the next few days. Please search in the following period{endl}";
	            	Helpers.showAlerts(this,{'errors':{'error':error_msg}},false,true);
	            });
	        }
		}else{
			this.setState({'custom_select':true});	
		}				
	}

	askNextPeriodProposals(){		
		let oldState=Object.assign({}, this.state);	
        oldState.alerts=[];
        oldState.alertsField=[];
        oldState.next_period_check=true;
    	this.setState(oldState);
		Helpers.doRequest('post','intervention/proposals',{'fls_replan':'1','status':'1','dateFrom':new Date(this.props.data.next_period_start*1000),'dateTo':new Date(this.props.data.next_period_end*1000)})
            .then(data=>{            	
				oldState.proposal_selected=false;
				oldState.next_month_show=true; 
                oldState.custom_proposal=data;
            	this.setState(oldState);
            }).catch(error=>{
            	//oldState.next_period_check=false;
            	oldState.proposal_selected=false;
				oldState.next_month_show=true; 
				oldState.custom_proposal={'proposals':[]};
            	this.setState(oldState);
            	//Helpers.showAlerts(this,{'errors':{'error':"There was an error while retrieving proposals. Try again"}});
            });
	}

	askNextMonthProposals(){	
		let oldState=Object.assign({}, this.state);	
        oldState.alerts=[];
        oldState.alertsField=[];
    	this.setState(oldState);	
		Helpers.doRequest('post','intervention/proposals',{'fls_replan':'1','status':'1','dateFrom':new Date(this.props.data.next_month_start*1000),'dateTo':new Date(this.props.data.next_month_end*1000)})
            .then(data=>{ 
				oldState.next_month_check=true;
				oldState.proposal_selected=false;
				oldState.custom_date_proposal_show=true; 
                oldState.custom_proposal=data;
            	this.setState(oldState);
            }).catch(error=>{
            	oldState.next_month_check=true;
				oldState.proposal_selected=false;
				oldState.custom_date_proposal_show=true; 
				oldState.custom_proposal={'proposals':[]};
            	this.setState(oldState);
            	//Helpers.showAlerts(this,{'errors':{'error':"There was an error while retrieving proposals. Try again"}});
            });
	}

	selectProposal(index,value){
		let oldState=Object.assign({}, this.state);	
		for(let x in oldState.custom_proposal.proposals){
			oldState.custom_proposal.proposals[x].is_default = parseInt(x) === parseInt(index) ? '1' : '0';
		}
		oldState.proposal_selected=true;
		this.setState(oldState);
	}

	confirmProposal(proposal){
		if(this.props.is_replan){
			//Unschedule first
			Helpers.doRequest('post','intervention/proposals',{'fls_replan':'1','status':'4','withoutRemove':1})
	            .then(data=>{   
	            	//replan
	                Helpers.doRequest('post','intervention/proposals',{'fls_replan':'1','custom_condition':'0','status':'2','dateFrom':new Date(proposal.plannedDateTmstmp*1000),'timeFrom':proposal.time,'timeslot_id':proposal.timeslot_id,'planeddate':new Date(this.props.data.original_planeddate*1000),'startdate':this.props.data.original_startdate})
		            .then(resp=>{   
		                this.props.confirmChange(resp);
		            }).catch(error=>{
		            	Helpers.showAlerts(this,error,false,true);
		            });
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,false,true);
	            });
		}else{
			Helpers.doRequest('post','intervention/proposals',{'custom_condition':'0','status':'2','dateFrom':new Date(proposal.plannedDateTmstmp*1000),'timeFrom':proposal.time,'timeslot_id':proposal.timeslot_id,'planeddate':new Date(this.props.data.original_planeddate*1000),'startdate':this.props.data.original_startdate})
	            .then(resp=>{   
	                this.props.confirmChange(resp);
	            }).catch(error=>{
	            	Helpers.showAlerts(this,error,false,true);
	            });
		}	
	}

	setStartDate(date){
		let oldState=Object.assign({}, this.state);	 
        oldState.custom_proposal.start_date=date;
        if(oldState.custom_proposal.end_date){
        	oldState.advanced_search_disabled=false;
        }
        let minEndDate=new Date(date);
        oldState.minEndDate=minEndDate.setDate(minEndDate.getDate()+1);
        if(oldState.custom_proposal.end_date && oldState.custom_proposal.end_date<oldState.minEndDate){
        	oldState.custom_proposal.end_date=oldState.minEndDate;
        }
        this.setState(oldState);
	}

	setEndDate(date){
		let oldState=Object.assign({}, this.state);	 
        oldState.custom_proposal.end_date=date;
        if(oldState.custom_proposal.start_date){
        	oldState.advanced_search_disabled=false;
        }
        this.setState(oldState);
	}

	searchProposals(){
		let tmp_obj={'fls_replan':(this.props.is_replan ? '1': ''),'status':'1','dateFrom':this.state.custom_proposal.start_date,'dateTo':this.state.custom_proposal.end_date};
		let selected_days=[];
		for(let x in this.state.days){
			if(this.state.days[x].checked){
				selected_days.push(this.state.days[x].id);
			}
		}
		if(selected_days.length){
			tmp_obj.day=selected_days;
		}
		let selected_timeslots=[];
		for(let x in this.state.timeslots){
			if(this.state.timeslots[x].checked){
				selected_timeslots.push(this.state.timeslots[x].id);
			}
		}
		if(selected_timeslots.length && selected_timeslots.length === 1){
			tmp_obj.timeslot_id=selected_timeslots[0];
		}
		let oldState=Object.assign({}, this.state);	
        oldState.alerts=[];
        oldState.alertsField=[];
    	this.setState(oldState);
		Helpers.doRequest('post','intervention/proposals',tmp_obj)
            .then(data=>{ 
                oldState.custom_proposal.proposals=data.proposals;
                oldState.disabled_select_proposal=false;
            	this.setState(oldState);
            	if(!data.proposals.length){
            		Helpers.showAlerts(this,{'errors':{'error':"{l}Sorry, we couldn't find a time for you. Adjust your search criteria and try again{endl}"}},false,true);
            	}
            }).catch(error=>{
            	//Helpers.showAlerts(this,{'errors':{'error':"There was an error while retrieving proposals. Try again"}});
            	Helpers.showAlerts(this,{'errors':{'error':"{l}Sorry, we couldn't find a time for you. Adjust your search criteria and try again{endl}"}},false,true);
            });
	}

	selectDay(index,value){
		let oldState=Object.assign({}, this.state);	 
		oldState.days[index].checked=value;
        if(oldState.custom_proposal.start_date && oldState.custom_proposal.end_date){
        	oldState.advanced_search_disabled=false;
        }
        this.setState(oldState);
	}

	selectInterval(index,value){
		let oldState=Object.assign({}, this.state);	 
        oldState.timeslots[index].checked=value;
        this.setState(oldState);
	}

	postponeInt(){
		let oldState=Object.assign({}, this.state);	
		Helpers.doRequest('get','intervention/postpone',{})
            .then(data=>{ 
            	oldState.postpone_data.options=data.options;
            	oldState.postpone_data.selected="";
                oldState.show_postpone_modal=true;
            	this.setState(oldState);
            }).catch(error=>{
            	//
            });
	}

	confirmPostpone(){
		let oldState=Object.assign({}, this.state);	
		let tmp_obj={'fls_replan':(this.props.is_replan ? '1': ''),'status':'4','mark_cancelled':'1','cancel_reason_id':'11','on_hold_until_date':oldState.postpone_data.selected.code};
		Helpers.doRequest('post','intervention/proposals',tmp_obj)
            .then(resp=>{ 
                oldState.show_postpone_modal=false;
            	this.setState(oldState);
            	this.props.postponed();
            }).catch(error=>{
            	Helpers.showAlerts(this,error,false,true);
            });
	}

	render(){
		const appLang=Store.retrieveLanguage();
		const generalMsg = this.state.alerts.map((item, key) =>
			<div className="d-flex p-2 bg-cozie-red mb-5" key={key}>
              <div className="body-200 ff-news text-white strong- link- col-12 px-0 py-3">{Helpers.translateHtml(item.msg, this.props.t)}</div>
              <span className="f-icon f-icon-close text-white f-icon-small" onClick={() => Helpers.closeAlert(this,key)}></span>
            </div>
	    );
		return (
			<div className="card bg-white col-lg-7 px-5">
	            {this.props.is_replan && <div className="headings-800 ff-medium text-gradient strong- link- mt-5 px-0 cozie--title">{this.props.t(Helpers.encodeWord('Choose another date'))}</div>}
	            {!this.props.is_replan && <div className="headings-800 ff-medium text-gradient strong- link- mt-5 px-0 cozie--title">{this.props.t(Helpers.encodeWord('Choose a date'))}</div>}
	            {this.state.custom_proposal.proposals.length > 0 && <div className="cozie--blue-buttons-wrap col-12 col-lg-8 p-0 mt-3">
	            	{
		             	this.state.custom_proposal.proposals.map((proposal,idx) => (
		             		<div className={"col-12 d-flex py-3 justify-content-between align-items-center my-3 select-proposal"+(proposal.is_default === '1' ? " active": "")} key={idx}>
				                <p className="body-100 ff-news mb-0">{this.props.t(Helpers.encodeWord(proposal.plannedDay))} {proposal.plannedDate} | {proposal.timeslot_interval}</p>
				                {proposal.is_default !== "1" && <input type="button" name="" value={this.props.t(Helpers.encodeWord('Choose this date'))} className="binb body-100 ff-news py-0 px-1" disabled={this.state.disabled_select_proposal} onClick={(e) => this.selectProposal(idx,'1')} />}
				            	{proposal.is_default === "1" && this.state.proposal_selected && <Button variant="success" className="confirm btn-sm rounded-pill mb-2 white_text position-absolute font-size-10 end-0" onClick={()=>this.confirmProposal(proposal)}>{this.props.t(Helpers.encodeWord('Confirm appointment'))}</Button>}
				            </div>			
		    			))
	    			}	            
	            </div>}
	            {this.props.is_replan && <div className="body-400 ff-medium text-blue strong- link- px-0 mt-3">{this.props.t(Helpers.encodeWord('None of these dates fit'))}?</div>}            
	            {!this.state.custom_select && <div className="cozie--bottom-buttons-wrap d-flex flex-wrap mt-4 mb-5">
	              <div className='col-12 col-sm-6 col-md-5 col-lg-3 mb-1 mb-lg-0 text-center p-0 me-2' onClick={()=>(this.state.next_period_check ? ()=>{} : this.askNextPeriodProposals())}>
	                <p className="body-100 ff-news mb-0">{this.props.t(Helpers.encodeWord('Next period'))}</p>
	                <p className="body-100 ff-news mb-0">{(this.props.data.next_period_text ? this.props.data.next_period_text : '')}</p>
	              </div>
	              {this.state.next_month_show && <div className='col-12 col-sm-6 col-md-5 col-lg-3 mb-3 mb-lg-0 text-center p-0 me-2' onClick={()=>(this.state.next_month_check ? ()=>{} : this.askNextMonthProposals())}>
	                <p className="body-100 ff-news mb-0">{this.props.t(Helpers.encodeWord('Next month'))}</p>
	                <p className="body-100 ff-news mb-0">{(this.props.data.next_month_text ? this.props.data.next_month_text : '')}</p>
	              </div>}
	              {this.state.custom_date_proposal_show && <input type="button" name="" value={this.props.t(Helpers.encodeWord('Select a period yourself'))}
	                className='single--orange py-3 px-4 d-flex align-items-center justify-content-center ff-news body-200' onClick={()=>Helpers.updateStateValue(this,[true,true,false],['disabled_select_proposal','custom_select','proposal_selected'])} />}
	            </div>}      
	            {this.state.custom_select && <div className="cozie--chices-wrap d-flex flex-column mt-4 mb-5">
	              <div className="body-300 ff-medium text-cozie-text-grey strong- link- mt-3 col-12 px-0">{this.props.t(Helpers.encodeWord('In which period'))}?</div>
	              <div className="body-200 ff-news text-cozie-text-grey strong- link- mt-3 mb-5 col-12 px-0">{this.props.t(Helpers.encodeWord('For an optimal result, search the calendar per period of 14 days'))}.</div>
	              <div className="col-12 p-0 d-flex my-3">
	                <div className="col-6 pl-0">
	                  <Form.Label className="text-muted fs-1-5">{this.props.t(Helpers.encodeWord('from'))}</Form.Label>
	                  <DatePicker
			    		  selected={this.state.custom_proposal.start_date}
					      onChange={date => this.setStartDate(date)}
					      minDate={this.state.minStartDate}
					      maxDate={(this.props.data.max_planned_date_tmstmp ? new Date(this.props.data.max_planned_date_tmstmp*1000) : new Date())}
					      dateFormat="dd/MM/yyyy"
					      customInput={<DatePickerInput />}
					      locale={appLang}
					      calendarStartDay={1}
					    />
	                </div>
	                <div className="col-6 pr-0">
	                  <Form.Label className="text-muted fs-1-5">{this.props.t(Helpers.encodeWord('untill'))}</Form.Label>
	                  <DatePicker
			    		  selected={this.state.custom_proposal.end_date}
					      onChange={date => this.setEndDate(date)}
					      minDate={this.state.minEndDate}
					      maxDate={(this.props.data.max_planned_date_tmstmp ? new Date(this.props.data.max_planned_date_tmstmp*1000) : new Date())}
					      dateFormat="dd/MM/yyyy"
					      customInput={<DatePickerInput />}
					      locale={appLang}
					      calendarStartDay={1}
					    />
	                </div>
	              </div>
	              <div className="body-300 ff-medium text-cozie-text-grey strong- link- my-3 col-12 px-0">{this.props.t(Helpers.encodeWord('On which days'))}?</div>
	              <div className="cozie--blue-buttons-wrap mb-1">
	              	{
		    			this.state.days.map((day,idx) => (
		    				<input type="button" key={idx} className={"cozie--blue-buttons-wrap--blue body-100 ff-news py-3 px-5 mr-3 mt-1"+(day.checked && day.checked === true ? " active" : "")} value={this.props.t(Helpers.encodeWord(day.name))} name="" onClick={(e) => this.selectDay(idx,!day.checked)}/>
		    			))
		    		}
	              </div>
	              <div className="body-300 ff-medium text-cozie-text-grey strong- link- my-3 col-12 px-0">{this.props.t(Helpers.encodeWord('At what time'))}?</div>
	              <div className="cozie--blue-buttons-wrap d-flex">
	              	{ (this.state.timeslots ? 
		    			this.state.timeslots.map((timeslot,idx) => (
		    				<input type="button" key={idx} name="" value={timeslot.name} className={"cozie--blue-buttons-wrap--blue body-100 ff-news py-3 px-4 mr-3"+(timeslot.checked && timeslot.checked === true ? " active" : "")} onClick={(e) => this.selectInterval(idx,!timeslot.checked)} />
		    			))
		    			: null)				    			
		    		}        
	              </div>
	              <div className="justify-content-center cozie--blue-buttons-wrap d-flex mt-4">
	                <input type="button" name="" value={this.props.t(Helpers.encodeWord('Search proposal'))}
	                  className='single--orange--reversed py-3 px-4 d-flex ff-news body-200' disabled={this.state.advanced_search_disabled} onClick={()=>this.searchProposals()} />
	              </div>	              
	            </div>}
	            {!this.state.next_period_check && this.props.show_postpone && <React.Fragment>
	            	<hr className="my-12"/>
	            	<div className="cozie--bottom-buttons-wrap-reversed d-flex flex-wrap mt-4 mb-5">
		              <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-1 mb-lg-0 text-center p-0 me-2' onClick={()=>this.postponeInt()}>
		                <p className="body-100 ff-news mb-0">{this.props.t(Helpers.encodeWord('Postpone my maintenance to a later time'))}</p>
		              </div>
		            </div>
	            </React.Fragment>}
              	<Modal
					show={this.state.show_postpone_modal}
			      	size="md"
			      	aria-labelledby="contained-modal-label"
			      	centered
			      	backdrop="static"
			      	onHide={()=>Helpers.updateStateValue(this,false,'show_postpone_modal')}
			    >
				    <Modal.Body>
				    	<div className="col-12 p-0 d-flex my-3">
			                <div className="col-12 pl-0 pr-0">
			                  <Form.Label className="text-muted fs-1-5">{this.props.t(Helpers.encodeWord('Postpone my maintenance to'))}</Form.Label>
			                  <Select options={this.state.postpone_data.options}
						    		value={this.state.postpone_data.selected ? this.state.postpone_data.selected : []} 
						    		className="selectize"
		      						isClearable={false}
		      						isSearchable={false}
		      						placeholder={this.props.t(Helpers.encodeWord('Select'))}
						    		getOptionLabel={option => this.props.t(Helpers.encodeWord(option.month))+' '+option.year}
						    		getOptionValue={option => option.code}
						    		onChange={(value)=>Helpers.updateStateValue(this,value,'selected','postpone_data')}
						    	/>
			                </div>
			            </div>
				    </Modal.Body>
				    <Modal.Footer>
				    	<div className="cozie--blue-buttons-wrap d-flex">
				    	  <input type="button" name="" value={this.props.t(Helpers.encodeWord('Cancel'))}
	                        className="cozie--blue-buttons-wrap--red mr-1 px-4 d-flex ff-news body-200" onClick={()=>Helpers.updateStateValue(this,false,'show_postpone_modal')} />
			              <input type="button" name="" value={this.props.t(Helpers.encodeWord('Confirm'))}
			                className='single--green--reversed py-3 px-4 d-flex ff-news body-200' disabled={!this.state.postpone_data.selected} onClick={()=>this.confirmPostpone()} />
			            </div>
		      		</Modal.Footer>
			    </Modal>
	            {generalMsg}
	        </div>
		);
	}

}
export default CustomProposal;