const Store={
	state: {
		logged:false,
		accessToken:'',
		app_language:'nl',
		cookieStatus:false,
		cookiesAccepted:false
	},
	retrieveStatus(){
		return this.state.logged;
	},
	retrieveAccessToken(){
		return this.state.accessToken;
	},
	retrieveLanguage(){
		return this.state.app_language;
	},
	retrieveCookieStatus(){
		return this.state.cookieStatus;
	},
	retrieveCookiesAccepted(){
		return this.state.cookiesAccepted;
	},
	updateLanguage(language){
		this.state.app_language=language;
	},
	updateCookieStatus(value){
		this.state.cookieStatus=value;
	},
	updateCookiesAccepted(value){
		this.state.cookiesAccepted=value;
	},
	saveLoginData(payload){
		this.state.logged=true;
		this.state.accessToken=payload.code;
		this.state.app_language=payload.language;
		localStorage.setItem('CPData',JSON.stringify(payload));
	},
	updateLoginData(payload,bool){
		this.state.logged=true;
		this.state.accessToken=payload.code;
		this.state.app_language=payload.language;
		if(bool){
			localStorage.setItem('CPData',JSON.stringify(payload));
		}	
	},
	clearData(state){
		this.state.logged=false;
		this.state.accessToken='';
		this.state.app_language='nl';
		this.state.cookieStatus=false;
		this.state.cookiesAccepted=false;
		localStorage.removeItem('CPData');
	}
}
export default Store;