/* eslint-disable */
import Store from './Store';
import Helpers from './Helpers.js';

function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+";domain=.engie.be;SameSite=None;Secure;path=/"+expires;

    eraseGAcookies()

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'new_subscriber',
        'formLocation': 'footer'
    });
    setTimeout(function() {
    window.dataLayer.push({
        'event': 'cookies_consent',
        'app_accepted': value,
    });
    }, 1000);
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}

function eraseGAcookies() {
    document.cookie = "_ga= ;domain=.engie.be;path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
    document.cookie = "_gat_ga_cookie= ;domain=.engie.be;path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
    document.cookie = "_gid= ;domain=.engie.be;path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
}

const CookieBar={
	init() {
		let genThis=this;
		/////////////////////////////////////////////////////
		////// LANGUAGE
		/////////////////////////////////////////////////////
		var tc_vars = {
		  env_language: Store.retrieveLanguage().toUpperCase(),
		  env_segment: 'residential',
		}

	    var env_language = tc_vars['env_language'];

	    if (env_language) {
	        if (env_language === "FR") {lang = "fr";}
	        if (env_language === "NL") {lang = "nl";}
	        if (env_language === "EN") {lang = "en";}
	    }
	    var lang = Store.retrieveLanguage();
	    var env_segment = tc_vars['env_segment'];

	    var cookie_value = 'required';
		var eventLabel_value = 'required';

		var old_cookiebars = document.querySelectorAll(".cookies-v2, .cookie-bar__overlay");

		for(let i = 0; i < old_cookiebars.length; i++ ) {
		    old_cookiebars[i].remove();
		}
		
		var cookiebar_links = {
		    fr: {
		        cookie_policy_link: {
		            residential: "https://www.engie.be/fr/politique-cookies",
		            business: "https://www.engie.be/fr/business/politique-cookies"
		        },
		        privacy_policy_link: {
		            residential: "https://www.engie.be/fr/politique-vie-privee",
		            business: "https://www.engie.be/fr/business/privacy_security"
		        }
		    },
		    nl: {
		        cookie_policy_link: {
		            residential: "https://www.engie.be/nl/cookiebeleid",
		            business: "https://www.engie.be/nl/business/cookiebeleid"
		        },
		        privacy_policy_link: {
		            residential: "https://www.engie.be/nl/privacybeleid",
		            business: "https://www.engie.be/nl/business/privacy_security"
		        }
		    },
		    en: {
		        cookie_policy_link: {
		            residential: "",
		            business: "https://www.engie.be/fr/business/policy-cookies"
		        },
		        privacy_policy_link: {
		            residential: "",
		            business: "https://www.engie.be/en/business/privacy-security"
		        }
		    }
		}
		var cookiebar_content = {
		    fr: {
		        step1: {
		            title: 'Nous respectons votre vie privée',
		            msg: '<p>ENGIE utilise des cookies afin de garantir le bon fonctionnement de son site web, personnaliser celui-ci en fonction de vos intérêts et optimiser votre expérience de navigation. Certains cookies permettent de personnaliser nos messages publicitaires via des bannières en ligne ou via message direct. Certains partenaires d’ENGIE peuvent installer des cookies sur notre site web afin de personnaliser la publicité qui vous est présentée en ligne. <br><br>Pour plus d’informations sur notre utilisation de cookies, vous pouvez consulter notre <a href="' + cookiebar_links[lang]["cookie_policy_link"][env_segment] + '" target="_blank">politique en matière de cookies ici</a>. Cliquez sur "Accepter" afin d’accepter tous les cookies et de continuer directement vers le site web ou cliquez sur "Gérer vos cookies" afin d’adapter vos préférences.</p>',
		            btn: 'Tout accepter',
		            list_txt: 'Liste de tous les cookies',
		            manage_txt: 'Gérer vos cookies',
		            optout_txt: 'Cette bannière fait l’objet de statistiques anonymes. Si vous ne souhaitez pas participer <a href="javascript:void(0)" id="optout-link" class="">cliquez ici</a>.',
		            optout_confirmation: 'Merci'
		        },
		        step2: {
		            title: 'Utilisation des cookies',
		            msg: '<p>ENGIE utilise des cookies sur son site web afin d’en garantir le bon fonctionnement et pour connaître vos préférences et intérêts en vue d’optimaliser votre expérience de navigation. Nous personnalisons également notre site selon vos intérêts, ainsi que certains sites partenaires d’ENGIE. Pour plus d’informations sur notre utilisation de cookies, vous pouvez consulter <a href="' + cookiebar_links[lang]["cookie_policy_link"][env_segment] + '" target="_blank">notre politique en matière de cookies ici</a>.</p>',
		            options: {
		                "1": {
		                    title: 'Cookies fonctionnels',
		                    txt: '<p>Cookies nécessaires au bon fonctionnement de nos sites web et apps. Ces cookies sont nécessaires, par exemple, pour vous connecter à l’espace client ou pour retenir votre choix de langue. Ces cookies ne peuvent pas être désactivés.</p>'
		                },
		                "2": {
		                    title: 'Cookies analytiques',
		                    txt: '<p>Cookies qui collectent des informations sur la façon dont les visiteurs utilisent nos sites web et apps (pages visitées, durée moyenne de la visite, …) afin d’améliorer le fonctionnement de ceux-ci, pour les adapter le contenu aux besoins des visiteurs et optimiser l’expérience de navigation.</p>'
		                },
		                "3": {
		                    title: 'Cookies de confort',
		                    txt: '<p>Cookies destinés à accroître votre confort de navigation et à vous offrir une expérience de navigation plus personnalisée, par exemple, en réorganisant le contenu d’une page ou en préremplissant certaines informations dans un formulaire.</p>'
		                },
		                "4": {
		                    title: 'Cookies commerciaux et de médias sociaux',
		                    txt: '<p>Cookies qui collectent des informations sur votre comportement de navigation afin de vous proposer des publicités ou des contenus qui vous intéressent, sur nos sites web et apps, mais également en dehors de ceux-ci via des bannières ou via message direct (comme par exemple e-mail, sms, courrier postal, …). Ces cookies peuvent également inclure des cookies tiers pour améliorer le contenu et le fonctionnement de notre site web et de nos apps, ou pour suivre et optimiser les bannières ENGIE sur d’autres sites web ou apps. Ces cookies vous permettent également de partager le contenu de nos sites web et apps avec d’autres personnes via les médias sociaux.</p>'
		                }
		            },
		            refuseall_btn: 'Tout refuser',
		            acceptall_btn: 'Tout accepter',
		            subbtn1: 'Politique cookies',
		            subbtn2: 'Vie privée',
		            savebtn: 'Enregistrer et quitter'
		        }
		    },
		    nl: {
		        step1: {
		            title: 'Wij respecteren uw privacy',
		            msg: '<p>ENGIE gebruikt cookies op haar website om de goede werking ervan te garanderen, deze te personaliseren op basis van jouw interesses en om je surfervaring te optimaliseren. Bepaalde cookies laten ons toe om onze reclameberichten te personaliseren via online banners of directe berichten. Bepaalde partners van ENGIE kunnen cookies op onze website installeren om de reclame die jou online wordt aangeboden te personaliseren. <br><br>Indien je meer informatie wenst over ons gebruik van cookies kan je <a href="' + cookiebar_links[lang]["cookie_policy_link"][env_segment] + '" target="_blank">ons cookiebeleid hier</a> raadplegen. Klik op “Accepteren” om alle cookies te aanvaarden en direct door te gaan naar de website of klik op “Je cookies beheren” om je cookievoorkeuren aan te passen.</p>',
		            btn: 'Alles accepteren',
		            list_txt: 'Lijst van alle cookies',
		            manage_txt: 'Je cookies beheren',
		            optout_txt: 'Deze banner registreert anonieme statistieken. Indien je niet wenst deel te nemen, <a href="javascript:void(0)" id="optout-link" class="">klik hier</a>.',
		            optout_confirmation: 'Dank U'
		        },
		        step2: {
		            title: 'Gebruik van cookies',
		            msg: '<p>ENGIE gebruikt cookies op haar website om de goede werking ervan te garanderen en om jouw voorkeuren en interesses te kennen zodat we je surfervaring kunnen optimaliseren. Wij personaliseren eveneens onze website en bepaalde websites van onze partners, op basis van jouw interesses. Indien je meer informatie wenst over ons gebruik van cookies kan je <a href="' + cookiebar_links[lang]["cookie_policy_link"][env_segment] + '" target="_blank">ons cookiebeleid hier</a> raadplegen.</p>',
		            options: {
		                "1": {
		                    title: 'Functionele cookies',
		                    txt: '<p>Cookies die nodig zijn om onze websites en apps correct te laten werken. Deze cookies zijn bijvoorbeeld nodig om je in te loggen op de klantenzone of om je taalkeuze te onthouden. Deze cookies kunnen niet gedeactiveerd worden.</p>'
		                },
		                "2": {
		                    title: 'Analytische cookies',
		                    txt: '<p>Cookies die informatie verzamelen over de manier waarop bezoekers onze websites en apps gebruiken (bezochte pagina’s, gemiddelde duur van het bezoek, …), om de werking ervan te verbeteren, om de inhoud aan te passen aan de wensen van de bezoekers en om je surfervaring te optimaliseren.</p>'
		                },
		                "3": {
		                    title: 'Comfortcookies',
		                    txt: '<p>Cookies om je surfcomfort te verhogen en je een meer gepersonaliseerde surfervaring te bezorgen, bijvoorbeeld door de inhoud van een pagina te herschikken of door bepaalde informatie vooraf in te vullen in een formulier.</p>'
		                },
		                "4": {
		                    title: 'Commerciële en social media cookies',
		                    txt: '<p>Cookies die informatie verzamelen over je surfgedrag met als doel je reclame of inhoud aan te bieden waarin je geïnteresseerd bent, op onze websites en apps, maar ook daarbuiten via banners of directe berichten (zoals bijvoorbeeld e-mail, sms, via de post, …). Dit kunnen ook cookies van derden zijn om de inhoud en de werking van onze website en apps te verbeteren of om de ENGIE-banners op andere websites of andere apps op te volgen en te optimaliseren. Met deze cookies kan je ook de inhoud van onze websites en apps delen met andere personen via sociale media.</p>'
		                }
		            },
		            refuseall_btn: 'Weiger alles',
		            acceptall_btn: 'Accepteer alles',
		            subbtn1: 'Cookiebeleid',
		            subbtn2: 'Privacybeleid',
		            savebtn: 'Opslaan en vertrekken'
		        }
		    },
		    en: {
		        step1: {
		            title: 'We respect your privacy',
		            msg: '<p>ENGIE uses cookies on its website to guarantee the proper functioning thereof, to personalize it based on your interests and to optimize your surfing experience. Certains cookies allow to personalize our publicity messages through online banners or direct messages. Certians partners of ENGIE may also place cookies on our website in order to personalize the publicity that is shown to you online. <br><br>For more information on our use of cookies you can consult <a href="' + cookiebar_links[lang]["cookie_policy_link"][env_segment] + '" target="_blank">our cookie policy here</a>. Click on “Accept” to accept all cookies and directly continue to our website or click on “Managing your cookies” and adapt your cookie preferences.</p>',
		            btn: 'Accept all',
		            list_txt: 'List of all cookies',
		            manage_txt: 'Managing your cookies',
		            optout_txt: 'This banner registers anonymous statistics. If you do not want to participate, please <a href="javascript:void(0)" id="optout-link" class="">click here</a>.',
		            optout_confirmation: 'Thank you'
		        },
		        step2: {
		            title: 'Use of cookies',
		            msg: '<p>ENGIE uses cookies on its website to guarantee the proper functioning thereof and to know your preferences and interests to enable us to optimize your surfing experience. We also personalize our website and certain websites of our partners, based on your interests. For more information on our use of cookies you can consult <a href="' + cookiebar_links[lang]["cookie_policy_link"][env_segment] + '" target="_blank">our cookie policy here</a>.</p>',
		            options: {
		                "1": {
		                    title: 'Functional cookies',
		                    txt: '<p>Cookies that are necessary for our websites and apps to work properly. These cookies are needed, for example, to allow you to log in to the customer area or to remember your language choice. These cookies cannot be desactivated.</p>'
		                },
		                "2": {
		                    title: 'Analytical cookies',
		                    txt: '<p>Cookies that collect information about how visitors use our websites and apps (pages visited, average length of visit, …) to improve the operation thereof, to adapt the content to the visitors’ needs and to optimize your surfing experience. </p>'
		                },
		                "3": {
		                    title: 'Comfort cookies',
		                    txt: '<p>Cookies to increase your surfing comfort and to provide you with a more personalised surfing experience, for example, by reorganizing the content of a page or by prefilling certain information in a form.</p>'
		                },
		                "4": {
		                    title: 'Commercial and social media cookies',
		                    txt: '<p>Cookies that collect information about your surfing behaviour in order to offer you advertising or content tailored to your own interests, on our websites and apps, but also outside them via banners or via direct messages (for example via e-mail, sms, post,…). These may also include third-party cookies to improve the content and operation of our website and apps, or to monitor and optimise ENGIE banners on other websites or apps. These cookies also allow you to share the content of our websites and apps with other people via social media.</p>'
		                }
		            },
		            refuseall_btn: 'Refuse all',
		            acceptall_btn: 'Accept all',
		            subbtn1: 'Cookies',
		            subbtn2: 'Privacy',
		            savebtn: 'Save and exit'
		        }
		    }
		}
		var cookiebar_html = 
		    '<div id="cookiebar-nws" class="cookiebar-nws d-none-cookie">' +
		        '<div class="cookies-v2 cookie-bar__overlay">' +
		            '<div class="cookie-bar__bg"></div>' +
		        '</div>' +
		        '<div class="cookie-bar__content cookies-v2">' +
		            '<div class="cookie-bar__content__inner cookie-bar__content__inner__medium ">'+
		                '<div class="cookie-bar__header"></div>'+
		                '<div class="cookie-bar__body">'+
		    
		                    /**Step1**/
		                    '<div id="step1" class="cookie-consent__step1">'+
		                        '<div class="cookie-consent__logo"></div>'+
		                        '<div class="step1_txt-block">'+
		                            '<div class="cookie-bar__title d-block-cookie">'+
		                                cookiebar_content[lang]["step1"]["title"] +
		                            '</div>'+
		                            '<div class="cookie-bar__txt d-block-cookie">'+
		                                cookiebar_content[lang]["step1"]["msg"] +
		                            '</div>'+		

		                                '<button class="cookie-bar__btn_txt cookie-bar__btn_txt__alternative cookie-consent__accept-all">'+
		                                    cookiebar_content[lang]["step1"]["btn"] +
		                                '</button>'+
		                        '</div>'+
		                    
		                        '<div class="cookie-bar-row">'+
		                            '<div class="cookie-bar-col-6 cookies-list">'+
		                                '<a href="'+cookiebar_links[lang]["cookie_policy_link"][env_segment]+'" target="_blank" class="">'+
		                                    cookiebar_content[lang]["step1"]["list_txt"] +
		                                '</a>'+			
		                            '</div>'+
		                            '<div class="cookie-bar-col-6 manage-cookies">'+
		                                '<a href="javascript:void(0)" class="cookie-consent__more-options ">'+
		                                    cookiebar_content[lang]["step1"]["manage_txt"] +
		                                '</a>'+
		                            '</div>'+
		                            '<div class="cookie-bar-col-12 optout-text">'+
		                                    cookiebar_content[lang]["step1"]["optout_txt"] +
		                                '<div class="">'+
		                                    '<div class="remove-cookies-container alert alert-success">'+
		                                        '<p>'+
		                                            cookiebar_content[lang]["step1"]["optout_confirmation"] +
		                                        '</p>'+	
		                                    '</div>'+	
		                                '</div>'+
		                            '</div>'+
		                        '</div>'+		
		                    '</div>'+		

		                    
		                    /**Step2**/
		                    '<div id="step2" class="cookie-consent__step2 d-none-cookie">'+
		                        '<div class="padding-x-2">'+
		                            '<div class="cookie-bar-row d-flex-cookie justify-content-between-cookie align-content-center-cookie">'+
		                                '<div class="cookie-bar-col-12 cookie-bar-col-md-4 d-flex-cookie justify-content-center-cookie justify-content-md-start-cookie align-items-center-cookie padding-y-2">'+
		                                    '<div class="cookie-consent__logo cookie-consent__logo__small"></div>'+
		                                '</div>'+
		                                
		                                
		                                '<div class="cookie-bar-col-12 cookie-bar-col-md-4 padding-y-1 padding-y-md-2">'+
		                                    '<button class="cookie-bar__btn_txt cookie-bar__btn_txt__outline cookie-consent__unselect-all">'+
		                                        cookiebar_content[lang]["step2"]["refuseall_btn"] +
		                                    '</button>'+	
		                                '</div>'+	
		                                
		                                
		                                '<div class="cookie-bar-col-12 cookie-bar-col-md-4 padding-y-1 padding-y-md-2">'+
		                                    '<button class="cookie-bar__btn_txt cookie-bar__btn_txt__alternative cookie-consent__select-all">'+
		                                        cookiebar_content[lang]["step2"]["acceptall_btn"] +
		                                    '</button>'+	
		                                '</div>'+	
		                            '</div>'+
		                        '</div>'+
		                        '<div class="grey-border-top"></div>'+
		                        '<div class="padding-2 cookie-consent__scrollable">'+
		                            '<div class="cookie-bar__subtitle d-block-cookie">'+
		                                cookiebar_content[lang]["step2"]["title"] +
		                            '</div>'+
		                            '<div class="cookie-bar__text margin-b-4 d-block-cookie">'+
		                                cookiebar_content[lang]["step2"]["msg"] +
		                            '</div>'+	
		                            '<div class="pt-1 cookie-consent__list">'+	
		                            
		                                '<div class="d-flex-cookie grey-border-top padding-y-1 margin-y-1">'+	
		                                    '<div>'+	
		                                        '<div class="cookie-bar__title__small">'+	
		                                            cookiebar_content[lang]["step2"]["options"]["1"]["title"] +	
		                                        '</div>'+
		                                        '<div class="padding-l-1">'+
		                                            '<div class="cookie-bar__text">'+
		                                            cookiebar_content[lang]["step2"]["options"]["1"]["txt"] +
		                                            '</div>'+
		                                        '</div>'+
		                                    '</div>'+
		                                    '<div class="padding-l-1">'+
		                                        '<label class="cookie-bar__check__toggle">'+
		                                            '<input type="checkbox" class="custom-control-input" checked="checked" disabled="disabled" name="required">'+
		                                            '<span class=""></span>'+
		                                        '</label>'+
		                                    '</div>'+
		                                '</div>'+
		                                
		                                '<div class="d-flex-cookie grey-border-top padding-y-1 margin-y-1">'+
		                                    '<div>'+
		                                        '<div class="cookie-bar__title__small ff-medium pb-1">'+
		                                        cookiebar_content[lang]["step2"]["options"]["2"]["title"] +
		                                        '</div>'+
		                                        '<div class="padding-l-1">'+
		                                            '<div class="cookie-bar__text">'+
		                                            cookiebar_content[lang]["step2"]["options"]["2"]["txt"] +
		                                            '</div>'+	
		                                        '</div>'+
		                                    '</div>'+	
		                                    '<div class="padding-l-1">'+	
		                                        '<label class="cookie-bar__check__toggle">'+
		                                            '<input id="cookie-analytics" type="checkbox" class="cookie-control-input custom-control-input" name="analytics">'+
		                                            '<span class=""></span>'+
		                                        '</label>'+
		                                    '</div>'+	
		                                '</div>'+
		                                
		                                '<div class="d-flex-cookie grey-border-top padding-y-1 margin-y-1">'+	
		                                    '<div>'+	
		                                        '<div class="cookie-bar__title__small ff-medium pb-1">'+	
		                                        cookiebar_content[lang]["step2"]["options"]["3"]["title"] +	
		                                        '</div>'+	
		                                        '<div class="padding-l-1">'+	
		                                            '<div class="cookie-bar__text">'+	
		                                            cookiebar_content[lang]["step2"]["options"]["3"]["txt"] +	
		                                            '</div>'+	
		                                        '</div>'+	
		                                    '</div>'+
		                                    '<div class="padding-l-1">'+	
		                                        '<label class="cookie-bar__check__toggle">'+	
		                                            '<input id="cookie-optimization" type="checkbox" class="cookie-control-input custom-control-input" name="optimization">'+	
		                                            '<span class=""></span>'+	
		                                        '</label>'+	
		                                    '</div>'+	
		                                '</div>'+	
		                                
		                                '<div class="d-flex-cookie grey-border-top padding-y-1 margin-y-1">'+
		                                    '<div>'+
		                                        '<div class="cookie-bar__title__small ff-medium pb-1">'+
		                                        cookiebar_content[lang]["step2"]["options"]["4"]["title"] +
		                                        '</div>'+	
		                                        '<div class="padding-l-1">'+	
		                                            '<div class="cookie-bar__text">'+	
		                                            cookiebar_content[lang]["step2"]["options"]["4"]["txt"] +	
		                                            '</div>'+	
		                                        '</div>'+	
		                                    '</div>'+	
		                                    '<div class="padding-l-1">'+
		                                        '<label class="cookie-bar__check__toggle">'+
		                                            '<input id="cookie-remarketing" type="checkbox" class="cookie-control-input custom-control-input" name="remarketing">'+
		                                            '<span class=""></span>'+
		                                        '</label>'+
		                                    '</div>'+	
		                                '</div>'+
		                                
		                            '</div>'+	
		                        '</div>'+          
		                        '<div class="grey-border-top"></div>'+
		                        '<div class="padding-x-2">'+
		                            '<div class="cookie-bar-row d-flex-cookie justify-content-between-cookie align-content-center-cookie">'+
		                                '<div class="cookie-bar-col-12 cookie-bar-col-md-6 padding-y-1 padding-y-md-2 order-2 order-md-1 d-flex-cookie flex-wrap-cookie align-items-center-cookie justify-content-center-cookie justify-content-md-start-cookie responsive-order-cookie-2 responsive-order-cookie-md-1">'+
		                                    '<a href="'+cookiebar_links[lang]["cookie_policy_link"][env_segment]+'" target="_blank" class="body-100 padding-x-2">'+
		                                    cookiebar_content[lang]["step2"]["subbtn1"] +
		                                    '</a>'+
		                                    '<a href="'+cookiebar_links[lang]["privacy_policy_link"][env_segment]+'" target="_blank" class="body-100 padding-x-2">'+
		                                    cookiebar_content[lang]["step2"]["subbtn2"] +
		                                    '</a>'+
		                                '</div>'+
		                                '<div class="cookie-bar-col-12 cookie-bar-col-md-6 padding-y-1 padding-y-md-2 order-1 order-md-2 save-btn-block responsive-order-cookie-1 responsive-order-cookie-md-2">'+
		                                    '<button class="cookie-bar__btn_txt cookie-bar__btn_txt__alternative cookie-consent__accept">'+
		                                    cookiebar_content[lang]["step2"]["savebtn"] +
		                                    '</button>'+
		                                '</div>'+	
		                            '</div>'+	
		                        '</div>'+
		                    '</div>'+
		                    /*/Step2*/                   
		                '</div>'+	
		            '</div>'+	
		        '</div>'+	
		    '</div>';

		document.body.insertAdjacentHTML('beforeend', cookiebar_html);		

		/////////////////////////////////////////////////////
		////// GA, TC & FUNC
		/////////////////////////////////////////////////////

		if (this.readCookie("cookie-consent") == null) {

		    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;

		    var s = document.getElementsByTagName("script")[0];
		    var b = document.createElement("script");
		        b.type = "text/javascript";
		        b.async = true;
		        b.src = "https://www.google-analytics.com/analytics.js";
		        s.parentNode.insertBefore(b, s);
		    ga('create', 'UA-92834960-5', 'auto', {'name':'ga_cookie'});
		    ga('ga_cookie.send', {
		    'hitType' : 'event',
		    'eventCategory' : 'Cookie Banner',
		    'eventAction' : 'First Step',
		    'eventLabel' : 'Page'
		    });
		    document.getElementById("cookiebar-nws").classList.remove("d-none-cookie");
		}

		document.getElementById("optout-link").addEventListener('click', function (e) {
		    window['ga-disable-UA-92834960-5'] = true;
		    var removeCookiesContainer = document.querySelector('.remove-cookies-container');
		    removeCookiesContainer.style.display = "block";
		    setTimeout(function(){ removeCookiesContainer.style.display = "none"; }, 3000);
		});

		document.querySelector('.cookie-consent__accept-all').addEventListener('click', function (e) {
		    ga('ga_cookie.send', {
		    'hitType' : 'event',
		    'eventCategory' : 'Cookie Banner',
		    'eventAction' : 'First Step',
		    'eventLabel' : ' All cookies accepted'
		    });
		    document.querySelector('.cookiebar-nws').style.display = "none";
		    cookie_value = 'required,analytics,optimization,remarketing';
		    createCookie("cookie-consent",cookie_value,365);
		    genThis.acceptCookie();
		});

		document.querySelector('.cookie-consent__accept').addEventListener('click', function (e) {
		    if (document.querySelector('input#cookie-analytics').checked == true) {
		        cookie_value = cookie_value + ',analytics'; 
		        eventLabel_value = eventLabel_value + ' - analytics';
		    }
		    if (document.querySelector('input#cookie-optimization').checked == true) {
		        cookie_value = cookie_value + ',optimization'; 
		        eventLabel_value = eventLabel_value + ' - optimization';
		    }
		    if (document.querySelector('input#cookie-remarketing').checked == true) {
		        cookie_value = cookie_value + ',remarketing'; 
		        eventLabel_value = eventLabel_value + ' - remarketing';
		    }
		    ga('ga_cookie.send', {
		        'hitType' : 'event',
		        'eventCategory' : 'Cookie Banner',
		        'eventAction' : 'Second step',
		        'eventLabel' : eventLabel_value
		    });
		    document.querySelector('.cookiebar-nws').style.display = "none";
		    createCookie("cookie-consent",cookie_value,365);
		    genThis.acceptCookie();
		});

		document.querySelector('.cookie-consent__more-options').addEventListener('click', function (e) {

		    ga('ga_cookie.send', {
		    'hitType' : 'event',
		    'eventCategory' : 'Cookie Banner',
		    'eventAction' : 'Second step',
		    'eventLabel' : 'Page'
		    });

		    var st1 = document.querySelector('.cookie-consent__step1');
		    var st2 = document.querySelector('.cookie-consent__step2');

		    st1.classList.add("d-none-cookie");
		    st1.classList.remove("d-block-cookie");

		    st2.classList.add("d-block-cookie");
		    st2.classList.remove("d-none-cookie");

		});	

		document.querySelector('.cookie-consent__unselect-all').addEventListener('click', function (e) {
		    var cookieControlInput = document.querySelectorAll('.cookie-control-input');
		    for(let i = 0; i < cookieControlInput.length; i++) {
		        cookieControlInput[i].checked = false;
		    }
		});	

		document.querySelector('.cookie-consent__select-all').addEventListener('click', function (e) {
		    var cookieControlInput = document.querySelectorAll('.cookie-control-input');
		    for(let i = 0; i < cookieControlInput.length; i++) {
		        cookieControlInput[i].checked = true;
		    }
		});	 

	},
	readCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	},
	acceptCookie(){
		Store.updateCookiesAccepted(true);
		Helpers.doRequest('post','intervention/cookie',{'accept':'1'})
            .then(data=>{   
            	//
            }).catch(error=>{
            	//
            });	
	}
}
export default CookieBar;