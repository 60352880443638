import React from 'react';
import './Login.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Helpers from './../../services/Helpers.js';
import FormValidation from './../../services/FormValidation.js';
import Store from './../../services/Store.js';
import Form from 'react-bootstrap/Form';
import CookieBar from './../../services/CookieBar.js';

class Login extends React.Component{
	_isMounted = false;

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	login:{},
	      	show_content:false,
	  	};
	  	this.validations={
	  		"postal_code":["required","float"]
	  	};
	}

	componentDidMount(){
		this._isMounted = true;
		Helpers.setUrlParams();
		Helpers.doRequest('get','auth/check')
            .then(data=>{
            	if(this._isMounted){
            		Store.updateCookiesAccepted(data.accepted_cookies);
	            	if(Store.retrieveLanguage() !== data.language){
	            		Store.updateLanguage(data.language);
	            		this.props.i18n.changeLanguage(data.language);
	            	}
	            	if(data.notice){
	            		this.props.history.push('/',{"msg":{'data':'{l}'+data.notice+"{endl}."}});
	            	}else{
	            		this.setState({'show_content':true});
		            	if(!Store.retrieveCookiesAccepted()){
							CookieBar.init();
						}
	            	} 
            	}         	        	
            }).catch(error=>{
            	if(this._isMounted){
            		this.props.history.push('/');
            	}       	
            });
	}

	doLogin(){
		let fieldTranslations={
    		"postal_code":this.props.t(Helpers.encodeWord('This'))
    	};
		let formErrors=FormValidation.checkValidity(this.validations,this.state.login,fieldTranslations,this.props.t);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true,true);
			return false;
		}
		let obj=Object.assign({},this.state.login);
		Helpers.doRequest('post','auth/login',obj)
            .then(data=>{
            	Store.saveLoginData(data);
            	window.ee.emit('storeChange');
				this.props.history.push("/int/"+data.code+"/main");
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	componentWillUnmount() {
    	this._isMounted = false;
  	}

	render(){
		const { t }=this.props;
		return (
			<div className="container">
		      {this.state.show_content && <div className="cozie--content-wrap d-flex justify-content-center align-items-center">
		        <div className="card bg-white col-12 col-md-6 col-lg-4 px-5">
		          <div className="headings-800 ff-medium text-gradient strong- link- mt-5 col-8 px-0 cozie--title">{t(Helpers.encodeWord('Connect'))} {t(Helpers.encodeWord('to continue'))}</div>
		          <div className="body-300 ff-news text-cozie-text-grey strong- link- my-4 col-12 px-0">{t(Helpers.encodeWord('On this website you can confirm or change your appointment proposal'))}</div>
		          <div className="col-12 px-0 mt-3">
		          	<Form.Label className={"col-form-label"+(this.state.errors.postal_code ? " col-form-label--error": "")}>{t(Helpers.encodeWord('Postal Code'))}</Form.Label>
		          	<Form.Control
		          		className={this.state.errors.postal_code ? "form-control--error": ""}
				    	value={this.state.login.postal_code || ''} 
				    	name="postal_code" 
				    	placeholder={t(Helpers.encodeWord('Postal Code'))} 
				    	autoComplete="off"
				    	onChange={(e)=>Helpers.updateStateValue(this,e.target.value,'postal_code','login')}
				    	onKeyPress={(e) => Helpers.submitForm(this,e,'doLogin') }/>
				    	{this.state.errors.postal_code && <p className="text-danger mt-1">{this.state.alertsField.postal_code}</p>}
		          </div>
		          <input type="button" name="" value={t(Helpers.encodeWord('Login'))} className='single--orange--reversed w-auto mr-auto mt-5 mb-5 py-3 px-5 ff-news body-200' disabled={(!this.state.login.postal_code ? true : false)} onClick={()=>this.doLogin()} />
		        </div>
		      </div>}
		    </div>
		);
	}

}
export default withRouter(withTranslation()(Login));